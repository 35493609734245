import { ParentComponent } from "solid-js";

interface IPropertyDisplayProps {
  title: string;
  labelClass?: string;
  contentClass?: string;
}

const PropertyDisplay: ParentComponent<IPropertyDisplayProps> = (props) => {
  return (
    <div class={`flex flex-col`}>
      <label class={`${props.labelClass ?? ""} text-sm text-gray-600`}>
        {props.title}
      </label>
      <div class={props.contentClass}>{props.children}</div>
    </div>
  );
};

export default PropertyDisplay;

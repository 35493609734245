import { useParams } from "@solidjs/router";
import { createQuery } from "@tanstack/solid-query";
import {
  Accessor,
  createContext,
  createSignal,
  JSX,
  ParentComponent,
  useContext,
} from "solid-js";

import { graphql } from "../../../schema";
import gqlClient from "../../../schema/gqlAuthorizedRequest";
import {
  CurrentLocationType,
  ImmigrationControlTypeType,
  SectionStatuses,
} from "../../../schema/graphql";
import { FlagsContext } from "../../config/FeatureFlagsContext";

interface BackButton {
  href: string;
  text: string;
}

const getApplicationSectionStatuses = graphql(`
  query getApplicantSectionStatuses($id: Int!) {
    getApplicant(id: $id) {
      immigrationControlType
      roleRequiresCar
      currentLocationAnswer
      sectionState {
        beforeYouStart {
          status
        }
        triage {
          status
        }
        yourDetails {
          status
        }
        yourHistory {
          status
        }
        yourDocuments {
          status
        }
        yourVisa {
          status
        }
        triage {
          status
        }
        accommodation {
          status
        }
        vehicle {
          status
        }
        flight {
          status
        }
        healthDeclaration {
          status
        }
        lifeInTheUk {
          status
        }
        yourUkDrivingLicence {
          status
        }
      }
    }
  }
`);

export const ApplicantProfileContext = createContext<{
  headerContentRight: Accessor<JSX.Element | null>;
  setHeaderContentRight: (elm: JSX.Element) => void;
  backButton: Accessor<BackButton | null>;
  setBackButton: (config: BackButton | null) => void;
  applicantSectionStatus: Accessor<Omit<
    SectionStatuses,
    "yourUkDrivingLicence"
  > | null>;
  immigrationControlType: Accessor<ImmigrationControlTypeType | null>;
  roleRequiresCar: Accessor<boolean>;
  currentLocationAnswer: Accessor<CurrentLocationType | null>;
}>();

export const ApplicantProfileContextProvider: ParentComponent = (props) => {
  const applicantId = Number.parseInt(useParams().id, 10);
  const featureFlags = useContext(FlagsContext);
  const [headerContentRight, setHeaderContentRight] =
    createSignal<JSX.Element | null>();
  const [backButton, setBackButton] = createSignal<BackButton | null>(null);
  const [immigrationControlType, setImmigrationControlType] =
    createSignal<ImmigrationControlTypeType | null>(null);
  const [applicantSectionStatus, setApplicantSectionStatus] = createSignal<Omit<
    SectionStatuses,
    "yourUkDrivingLicence"
  > | null>(null);
  const [roleRequiresCar, setRoleRequiresCar] = createSignal<boolean>(false);
  const [currentLocationAnswer, setCurrentLocationAnswer] =
    createSignal<CurrentLocationType | null>(null);

  createQuery(() => ({
    queryKey: ["applicant", applicantId, "applicationSectionStatuses"],
    queryFn: async () => {
      const result = await gqlClient.request(getApplicationSectionStatuses, {
        id: applicantId,
      });
      setApplicantSectionStatus(result.getApplicant.sectionState);
      setImmigrationControlType(
        result.getApplicant.immigrationControlType ?? null,
      );
      setRoleRequiresCar(result.getApplicant.roleRequiresCar ?? false);
      setCurrentLocationAnswer(
        result.getApplicant.currentLocationAnswer ?? null,
      );
      return result;
    },
    get enabled() {
      return featureFlags
        ?.flags()
        .ONBOARDING_WEB_ENABLE_SECTION_SUBMISSION_V1?.asBoolean();
    },
  }));

  return (
    <ApplicantProfileContext.Provider
      value={{
        headerContentRight,
        setHeaderContentRight,
        backButton,
        setBackButton,
        applicantSectionStatus,
        immigrationControlType,
        roleRequiresCar,
        currentLocationAnswer,
      }}
    >
      {props.children}
    </ApplicantProfileContext.Provider>
  );
};

export const getApplicantProfileContext = () =>
  useContext(ApplicantProfileContext);

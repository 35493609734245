import { ParentComponent } from "solid-js";

import { Layout } from "../../common/Layout";
import { ApplicantProfileContextProvider } from "./ApplicantProfileContext";
import { ApplicantProfileHeader } from "./ApplicantProfileHeader";
import { ApplicantProfileNav } from "./ApplicantProfileNav";

const ApplicantProfileRouter: ParentComponent = (props) => {
  return (
    <Layout>
      <ApplicantProfileContextProvider>
        <div class="flex size-full flex-col">
          <ApplicantProfileHeader />

          <ApplicantProfileNav />

          <div class="w-full pt-6">{props.children}</div>
        </div>
      </ApplicantProfileContextProvider>
    </Layout>
  );
};

export default ApplicantProfileRouter;

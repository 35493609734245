import { XClose } from "@liftedcare/solidjs-untitled-icons/duocolor";
import { Component, createSignal, JSX, Show } from "solid-js";
import { Motion, Presence } from "solid-motionone";

import { Button } from "../common/Button";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "../modal/Modal";

export interface IToastProps {
  id: string;
  title?: string;
  content?: JSX.Element;
  details?: JSX.Element;
  closeable?: boolean;
  autoCloseMs?: number;
  iconComponent: JSX.Element;
  close?: (id?: string) => void;
  className?: string;
  containerClassName?: string;
}

export const Toast: Component<IToastProps> = (props) => {
  const resolvedAutoCloseMs = props.autoCloseMs ?? 5000;
  const animationTimeMs = 300;
  const [hide, setHide] = createSignal<boolean>(false);
  const [showDetailsModal, setShowDetailsModal] = createSignal<boolean>(false);
  if (resolvedAutoCloseMs > 0) {
    setTimeout(() => {
      closeToast(props.id);
    }, resolvedAutoCloseMs);
  }

  function closeToast(id: string) {
    if (showDetailsModal()) {
      return;
    }
    setHide(true);
    // delay close so anim can finish
    setTimeout(() => {
      props.close?.(id);
    }, animationTimeMs);
  }

  function closeModal(id: string) {
    setShowDetailsModal(false);
    setHide(true);
    // delay close so anim can finish
    setTimeout(() => {
      props.close?.(id);
    }, animationTimeMs);
  }
  return (
    <>
      <Presence>
        <Show when={!hide()}>
          <Motion.div
            class={`relative z-50 mb-2 ml-auto rounded-lg border bg-white px-6 py-4 shadow-lg ${props.className}`}
            role="alert"
            initial={{ y: "100%" }}
            animate={{ y: "0" }}
            exit={{ x: "100%" }}
            transition={{ duration: 0.5 }}
          >
            <button
              class={`space-y-2 ${props.details ? "cursor-pointer" : ""}`}
              onClick={() => (props.details ? setShowDetailsModal(true) : null)}
            >
              <div class="flex items-center gap-4" aria-label={props.title}>
                {props.iconComponent}
                <h2 class="text-xl font-medium text-primary-700">
                  {props.title}
                </h2>
              </div>

              <div class="my-auto flex flex-col">
                <p class="block whitespace-pre-wrap text-gray-700 sm:inline">
                  {props.content}
                </p>
                <Show when={props.details}>
                  <p class="mt-3 w-full text-center text-xs text-gray-500">
                    Click to view details
                  </p>
                </Show>
              </div>
            </button>
            {props.closeable !== false && (
              <button
                aria-label="close toast"
                class="absolute bottom-0 right-0 top-2 h-10 px-4 py-3 hover:cursor-pointer"
                onClick={() => closeToast(props.id)}
              >
                <XClose class="h-4 text-slate-500" />
              </button>
            )}
          </Motion.div>
        </Show>
      </Presence>

      <Modal
        show={showDetailsModal()}
        onClose={() => closeModal(props.id)}
        size="sm"
      >
        <ModalHeader>
          <div class="flex items-center gap-4">
            {props.iconComponent}
            <h2 class="text-xl font-medium text-primary-700">
              {props.content}
            </h2>
          </div>
        </ModalHeader>
        <ModalBody>
          <div class="my-auto">
            <p class="block whitespace-pre-wrap text-gray-700 sm:inline">
              {props.details}
            </p>
          </div>
        </ModalBody>
        <ModalFooter class="justify-end">
          <Button color="secondary" onClick={() => closeModal(props.id)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

import { RouteDefinition } from "@solidjs/router";
import { lazy } from "solid-js";

export const authRoutes: RouteDefinition[] = [
  {
    path: "/login",
    component: lazy(() => import("../components/auth/Login")),
  },
  {
    path: "/register",
    component: lazy(() => import("../components/auth/Register")),
  },
  {
    path: "/register/agency",
    component: lazy(() => import("../components/agencies/AgencyBuilder")),
  },
  {
    path: "/forgotten-password",
    component: lazy(() => import("../components/auth/ForgotPassword")),
  },
  {
    path: "/forbidden",
    component: lazy(() => import("../components/Forbidden")),
  },
];

import { initializeApp } from "firebase/app";

import { Environment, getEnvironment } from "./src/utils/getEnvironment";

const defaultConfig = {
  apiKey: "AIzaSyD-r6cc-HNIrtjTr-Fjr7b7N9LtecvYJZ8",
  authDomain: "carer-onboarding-staging.firebaseapp.com",
  projectId: "carer-onboarding-staging",
  storageBucket: "carer-onboarding-staging.appspot.com",
  messagingSenderId: "96834259835",
  appId: "1:96834259835:web:c8890d4f2240fe592a4bde",
};

// Your web app's Firebase configuration
const environments: { [key in Environment]?: object } = {
  staging: defaultConfig,
  uat: {
    apiKey: "AIzaSyA1cOdcR9G1PxmYxFx52F2_gXnHvHqr8nc",
    authDomain: "carer-onboarding-uat.firebaseapp.com",
    projectId: "carer-onboarding-uat",
    storageBucket: "carer-onboarding-uat.appspot.com",
    messagingSenderId: "124192106652",
    appId: "1:124192106652:web:123536c918f48b02b46559",
  },
  production: {
    apiKey: "AIzaSyA_aLqbzSjZujBh-Ly0XGIqDyPco5AlcuM",
    authDomain: "carer-onboarding-prod.firebaseapp.com",
    projectId: "carer-onboarding-prod",
    storageBucket: "carer-onboarding-prod.appspot.com",
    messagingSenderId: "427861913887",
    appId: "1:427861913887:web:17e1eea7fae16e82df402a",
    measurementId: "G-QR4BNKQW6G",
  },
};
// Initialize Firebase
export const FirebaseApp = initializeApp(
  environments[getEnvironment()] ?? defaultConfig,
);

import { Component } from "solid-js";

import { Button } from "../common/Button";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "../modal/Modal";

export const UserNotRegisteredModal: Component<{
  show: boolean;
  onClose: () => void;
}> = (props) => {
  return (
    <Modal show={props.show} onClose={props.onClose}>
      <ModalHeader>You are not registered</ModalHeader>
      <ModalBody>
        <div>
          We can't find an account for that email address, please reach out to
          the support team who will be able to assist you.
        </div>
      </ModalBody>
      <ModalFooter class="flex gap-4">
        <Button color="secondary" class="w-full" onClick={props.onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

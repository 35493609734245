import { createQuery } from "@tanstack/solid-query";
import { Component, createSignal } from "solid-js";

import { graphql } from "../../../schema";
import gqlClient from "../../../schema/gqlAuthorizedRequest";
import type { GenerateExportTokenQuery } from "../../../schema/graphql";
import { Button } from "../../common/Button";
import DataLoadingSwitch from "../../common/DataLoadingSwitch";
import Modal, { IModalProps, ModalBody, ModalHeader } from "../../modal/Modal";
import { useToast } from "../../toast";

const getApplicantExportToken = graphql(`
  query generateExportToken($applicantId: Int!) {
    generateExportToken(applicantId: $applicantId)
  }
`);

export const ApplicantExportTokenModal: Component<
  IModalProps & { applicantId: number }
> = (props) => {
  const [timeoutInterval, setTimeoutInterval] =
    createSignal<null | NodeJS.Timeout>(null);

  const [secondsLeft, setSecondsLeft] = createSignal(0);

  const toast = useToast();

  const timeoutCounter = () => {
    const currentSecondsLeft = secondsLeft();
    if (currentSecondsLeft <= 0) {
      setSecondsLeft(0);
      const currentInterval = timeoutInterval();
      if (currentInterval) {
        clearInterval(currentInterval);
      }
      setTimeoutInterval(null);
      return;
    }
    setSecondsLeft(currentSecondsLeft - 1);
  };

  const exportTokenQuery = createQuery(() => ({
    queryKey: ["exportToken", props.applicantId],
    queryFn: async () => {
      const data: GenerateExportTokenQuery = await gqlClient.request(
        getApplicantExportToken,
        {
          applicantId: props.applicantId,
        },
      );
      setTimeoutInterval(setInterval(timeoutCounter, 1000));
      setSecondsLeft(5 * 60);
      return data;
    },
    get enabled() {
      return !!props.show;
    },
  }));

  const copyTokenToClipboard = async () => {
    const token = exportTokenQuery.data?.generateExportToken;
    if (!token) {
      return;
    }
    await navigator.clipboard.writeText(token);
    toast.success("Success", "Token copied to clipboard");
  };

  const onClose = () => {
    const currentInterval = timeoutInterval();
    if (currentInterval) {
      clearInterval(currentInterval);
    }
    setTimeoutInterval(null);
    props.onClose();
  };

  return (
    <Modal show={props.show} onClose={onClose}>
      <ModalHeader>Applicant export token</ModalHeader>
      <ModalBody>
        <DataLoadingSwitch loader={exportTokenQuery}>
          <p class="text-sm text-gray-500">
            Please paste this token into the CoS application extension
          </p>
          <div class="w-full whitespace-pre-wrap break-words rounded-md bg-gray-100 p-4">
            {exportTokenQuery.data?.generateExportToken}
          </div>
          <div class="flex flex-row items-center justify-between">
            Expires in: {`${Math.floor(secondsLeft() / 60)}`.padStart(2, "0")}:
            {`${secondsLeft() % 60}`.padStart(2, "0")}
            <Button class="self-end" onClick={copyTokenToClipboard}>
              Copy token
            </Button>
          </div>
        </DataLoadingSwitch>
      </ModalBody>
    </Modal>
  );
};

import { ClientError } from "graphql-request";

export function isGqlRequestError(
  err: unknown | ClientError,
): err is ClientError {
  return (
    "response" in (err as ClientError) &&
    typeof (err as ClientError).response === "object"
  );
}

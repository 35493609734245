import { Component, Show } from "solid-js";

import {
  ImmigrationControlTypeType,
  PermissionKeyEnum,
} from "../../../schema/graphql";
import { useAuth } from "../../auth/AuthContext";
import { ShowForRoles } from "../../common/ShowForRoles";
import { TabLink } from "../../tabs/TabLink";
import { Tabs } from "../../tabs/Tabs";
import { getApplicantProfileContext } from "./ApplicantProfileContext";

export const ApplicantProfileNav: Component<{ onClick?: () => void }> = () => {
  const auth = useAuth();

  const applicant = getApplicantProfileContext();

  const displayImmigrationOptions = () =>
    !(
      [
        ImmigrationControlTypeType.UkCitizen,
        ImmigrationControlTypeType.IndefiniteLeaveToRemain,
        ImmigrationControlTypeType.LeaveToRemain,
      ] as (ImmigrationControlTypeType | null)[]
    ).includes(applicant?.immigrationControlType() ?? null);

  return (
    <Show when={auth?.authenticatedUser()}>
      <div class="w-full overflow-x-auto">
        <Tabs class="gap-3 border-b-0">
          <TabLink href="compliance" pageTitle="Compliance" />
          <ShowForRoles permissions={[PermissionKeyEnum.ApplicantEdit]}>
            <TabLink href="actions" pageTitle="Actions" />
          </ShowForRoles>
          <TabLink href="profile" pageTitle="Profile" />
          <TabLink href="health-declaration" pageTitle="Health" />
          <TabLink href="history" pageTitle="History" />
          <TabLink href="identity" pageTitle="Identity" />
          <TabLink href="references" pageTitle="References" />
          <TabLink href="dbs" pageTitle="DBS" />
          <Show when={displayImmigrationOptions()}>
            <TabLink href="right-to-work" pageTitle="Immigration" />
          </Show>
          <TabLink href="accommodation" pageTitle="Housing" />
          <Show when={displayImmigrationOptions()}>
            <TabLink href="flights" pageTitle="Flights" />
          </Show>
          <Show when={applicant?.roleRequiresCar()}>
            <TabLink href="vehicles" pageTitle="Vehicles" />
          </Show>
          <TabLink href="signature-requests" pageTitle="Signatures" />
          <TabLink href="documents" pageTitle="Documents" />
          <TabLink href="comments" pageTitle="Comments" />
          <ShowForRoles permissions={[PermissionKeyEnum.ApplicantEdit]}>
            <TabLink href="app-config" pageTitle="App Config" />
          </ShowForRoles>
        </Tabs>
      </div>
    </Show>
  );
};

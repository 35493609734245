import { ParentComponent } from "solid-js";

import { LiftedLogo } from "../icons/LiftedLogo";
import { Layout } from "./Layout";

export const LoginLayout: ParentComponent<{ class?: string }> = (props) => {
  return (
    <Layout class="flex h-svh flex-col items-center bg-purple-200 !py-0">
      <div class="my-auto flex h-full w-[90svw] max-w-[700px] flex-col items-center justify-center space-y-12 bg-white p-4 sm:px-8 md:w-[60svw] lg:w-[50swv]">
        <LiftedLogo class="mx-auto w-2/3" />
        <div class="w-full space-y-8 p-2 md:px-4 lg:px-16">
          {props.children}
        </div>
      </div>
    </Layout>
  );
};

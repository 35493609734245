import { ArrowNarrowLeft } from "@liftedcare/solidjs-untitled-icons/duocolor";
import { useNavigate } from "@solidjs/router";
import { Component, Show } from "solid-js";

export const BackButton: Component<{
  to?: string;
  label?: string;
  class?: string;
}> = (props) => {
  const navigate = useNavigate();

  return (
    <button
      color="secondary"
      onClick={() => (props.to ? navigate(props.to) : history.back())}
      class="flex items-center gap-1 text-sm text-gray-500"
    >
      <ArrowNarrowLeft class="w-5" />
      <Show fallback="Back" when={props.label}>
        {props.label}
      </Show>
    </button>
  );
};

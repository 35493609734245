import {
  FilterLines,
  Menu01,
  Users01,
  XClose,
} from "@liftedcare/solidjs-untitled-icons/duocolor";
import { File02, ShieldTick } from "@liftedcare/solidjs-untitled-icons/duotone";
import {
  Briefcase02,
  CheckDone02,
  Home03,
  LogOut01,
  Settings01,
} from "@liftedcare/solidjs-untitled-icons/line";
import { Gift01 } from "@liftedcare/solidjs-untitled-icons/solid";
import { A, useMatch } from "@solidjs/router";
import {
  Component,
  createEffect,
  createMemo,
  createSignal,
  createUniqueId,
  onCleanup,
  onMount,
  ParentComponent,
  Show,
} from "solid-js";
import { Motion } from "solid-motionone";

import { AgencyStatus, UserRoleType } from "../../schema/graphql";
import { isDevelopment } from "../../utils/getEnvironment";
import { useAgencyContext } from "../auth/AgencyContext";
import { useAuth } from "../auth/AuthContext";
import { Button } from "../common/Button";
import { ShowForRoles } from "../common/ShowForRoles";
import { UserAvatar } from "../common/UserAvatar";
import { ShowForFeatureFlag } from "../config/ShowForFeatureFlag";
import { NavbarGroup } from "./NavbarGroup";

const NavLink: ParentComponent<{
  href: string;
  ariaLabel: string;
  onClick?: (e: Event) => void;
  class?: string;
  activeForRoutes?: string[];
  navCollapsed: boolean;
}> = (props) => {
  const isActive = createMemo(() => {
    if (props.activeForRoutes?.length) {
      return props.activeForRoutes?.some((r) => useMatch(() => r)());
    }
    return useMatch(() => `${props.href}/*`)();
  });

  return (
    <A
      class={`${props.class} relative flex w-full gap-2 rounded-md p-2 text-white hover:bg-primary-400`}
      classList={{
        "bg-primary-600": Boolean(isActive()),
      }}
      href={props.href}
      onClick={props.onClick}
      aria-label={props.ariaLabel}
    >
      {props.children}
    </A>
  );
};

const DEFAULT_TRANSITION_DURATION = 0.1;

export const Navbar: Component = () => {
  const elm: HTMLDivElement | null = null;
  const containerId = createUniqueId();

  const auth = useAuth();
  const agencyContext = useAgencyContext();

  const [collapsed, setCollapsed] = createSignal(true);
  const [animationComplete, setAnimationComplete] = createSignal(false);
  const [isSmallScreen, setIsSmallScreen] = createSignal(false);
  const [width, setWidth] = createSignal("");
  const [height, setHeight] = createSignal("");
  const [transitionDuration, setTransitionDuration] = createSignal(
    DEFAULT_TRANSITION_DURATION,
  );

  const onToggleCollapse = (newValue: boolean) => {
    setCollapsed(newValue);
    if (collapsed()) {
      if (isSmallScreen()) {
        setHeight("50px");
        setWidth("100%");
      } else {
        setHeight("100vh");
        setWidth("72px");
      }
    } else {
      if (isSmallScreen()) {
        setHeight("75vh");
        setWidth("100%");
      } else {
        setHeight("100vh");
        setWidth("17rem");
      }
    }
  };

  const documentClickListener = (e: MouseEvent) => {
    const inside = document
      .getElementById(containerId)
      ?.contains(e.target as Node);
    if (!inside && !collapsed()) {
      onToggleCollapse(true);
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
  };

  createEffect(() => {
    window.removeEventListener("click", documentClickListener);
    if (isSmallScreen()) {
      window.addEventListener("click", documentClickListener, {
        capture: true,
      });
    }
  });

  const onWindowResize = () => {
    // disable animation during resize otherwise the navbar jumps around
    setTransitionDuration(0);
    setTimeout(() => {
      setIsSmallScreen(window.innerWidth < 768);
      onToggleCollapse(true);
      // re-enable animation
      setTransitionDuration(DEFAULT_TRANSITION_DURATION);
    }, 1);
  };

  onMount(() => {
    onWindowResize();
    window.addEventListener("resize", onWindowResize);
  });

  onCleanup(() => {
    window.removeEventListener("click", documentClickListener);
    window.removeEventListener("resize", onWindowResize);
  });

  const nameParts = auth?.authenticatedUser()?.name?.split(" ");

  const showNavbar = () =>
    auth?.authenticatedUser()?.role === UserRoleType.Admin ||
    (agencyContext?.agency() &&
      agencyContext?.agency()?.status !== AgencyStatus.Onboarding);

  return (
    <Show when={showNavbar() && auth?.authenticatedUser()}>
      <Motion.div
        ref={elm!}
        class="absolute left-0 z-50 flex h-screen flex-col gap-y-4 bg-primary-700 from-primary-500 from-70% to-primary-300 p-4 shadow shadow-primary-600 max-md:bottom-0 md:bg-gradient-to-b"
        classList={{
          "overflow-auto": !collapsed(),
          "max-md:overflow-hidden": collapsed(),
        }}
        style={{
          "scrollbar-color": "#bcb7fb #785ff3",
        }}
        animate={{
          width: width(),
          height: height(),
        }}
        transition={{ duration: transitionDuration(), easing: "ease-in-out" }}
        onMotionStart={() => setAnimationComplete(false)}
        onMotionComplete={() => setAnimationComplete(true)}
        onMouseEnter={() => {
          if (isSmallScreen()) {
            return;
          }
          onToggleCollapse(false);
        }}
        onMouseLeave={() => {
          if (isSmallScreen()) {
            return;
          }
          onToggleCollapse(true);
        }}
      >
        <div class="flex h-full flex-col justify-between" id={containerId}>
          <div class="flex w-full grow flex-col gap-y-2 md:gap-y-6">
            <div class="flex w-full justify-center md:hidden">
              <button
                onClick={() => onToggleCollapse(!collapsed())}
                aria-label="Toggle menu"
              >
                <Show
                  when={collapsed()}
                  fallback={
                    <XClose class="pointer-events-none w-7 text-white" />
                  }
                >
                  <Menu01 class="pointer-events-none w-7 text-white" />
                </Show>
              </button>
            </div>

            <div class="flex w-full grow flex-col gap-y-2 md:gap-y-6">
              <div class="flex w-full grow flex-col gap-y-2 md:gap-y-6">
                {/* Home */}
                <NavbarGroup
                  header={
                    <>
                      <Home03 class="w-6" />
                      <Show when={!collapsed() && animationComplete()}>
                        Home
                      </Show>
                    </>
                  }
                  ariaLabel="Home"
                  navCollapsed={collapsed()}
                  animationComplete={animationComplete()}
                  activeForRoutes={[
                    "/candidates/sponsored*",
                    "/candidates/non-sponsored/*",
                  ]}
                >
                  <NavLink
                    class="!pl-12"
                    href="/candidates/sponsored-uk"
                    navCollapsed={collapsed()}
                    ariaLabel="Sponsored UK"
                  >
                    Sponsored UK
                  </NavLink>
                  <NavLink
                    class="!pl-12"
                    href="/candidates/sponsored-overseas"
                    navCollapsed={collapsed()}
                    ariaLabel="Sponsored Overseas"
                  >
                    Sponsored Overseas
                  </NavLink>
                  <NavLink
                    class="!pl-12"
                    href="/candidates/non-sponsored"
                    navCollapsed={collapsed()}
                    ariaLabel="Non Sponsored"
                  >
                    Non Sponsored
                  </NavLink>
                </NavbarGroup>

                {/* ATS */}
                <ShowForFeatureFlag key="ONBOARDING_WEB_ENABLE_ATS_V1">
                  <NavLink
                    href="/positions/list"
                    navCollapsed={collapsed()}
                    ariaLabel="Positions"
                  >
                    <Briefcase02 class="w-6" />
                    <Show when={!collapsed() && animationComplete()}>Jobs</Show>
                  </NavLink>
                  <NavbarGroup
                    expanded={true}
                    header={
                      <>
                        <FilterLines class="w-6" />
                        <Show when={!collapsed() && animationComplete()}>
                          Talent Pipeline
                        </Show>
                      </>
                    }
                    ariaLabel="Talent Pipeline"
                    navCollapsed={collapsed()}
                    animationComplete={animationComplete()}
                    activeForRoutes={[
                      "/applications/*",
                      "applications/profile/*",
                    ]}
                  >
                    <NavLink
                      class="!pl-12"
                      href="/applications/review"
                      navCollapsed={collapsed()}
                      ariaLabel="Applicant Review"
                    >
                      Review
                    </NavLink>
                    <NavLink
                      class="!pl-12"
                      href="/applications/screening"
                      navCollapsed={collapsed()}
                      ariaLabel="Screening"
                    >
                      Screening
                    </NavLink>
                    <NavLink
                      class="!pl-12"
                      href="/applications/interviewing"
                      navCollapsed={collapsed()}
                      ariaLabel="Interviewing"
                    >
                      Interviewing
                    </NavLink>
                    <NavLink
                      class="!pl-12"
                      href="/applications/rejected"
                      navCollapsed={collapsed()}
                      ariaLabel="Rejected"
                    >
                      Rejected
                    </NavLink>
                  </NavbarGroup>
                </ShowForFeatureFlag>

                {/* Team */}
                <NavbarGroup
                  header={
                    <>
                      <Users01 class="w-6" />
                      <Show when={!collapsed() && animationComplete()}>
                        Team
                      </Show>
                    </>
                  }
                  ariaLabel="Team"
                  navCollapsed={collapsed()}
                  animationComplete={animationComplete()}
                  activeForRoutes={["/applicants/*", "applicant/profile/*"]}
                >
                  <NavLink
                    navCollapsed={collapsed()}
                    href="/applicants/compliance-in-progress"
                    class="!pl-10"
                    activeForRoutes={[
                      "applicants/compliance-in-progress",
                      "applicant/profile/*",
                    ]}
                    ariaLabel="Overview"
                  >
                    <Show when={!collapsed() && animationComplete()}>
                      Overview
                    </Show>
                  </NavLink>

                  <ShowForFeatureFlag key="ONBOARDING_WEB_ENABLE_EVENTS_LIST_VIEW">
                    <NavLink
                      navCollapsed={collapsed()}
                      href="/applicants/events"
                      class="!pl-10"
                      activeForRoutes={["/applicants/events/*"]}
                      ariaLabel="Update Feed"
                    >
                      <Show when={!collapsed() && animationComplete()}>
                        Update Feed
                      </Show>
                    </NavLink>
                  </ShowForFeatureFlag>
                </NavbarGroup>

                <NavbarGroup
                  header={
                    <>
                      <CheckDone02 class="w-6" />
                      <Show when={!collapsed() && animationComplete()}>
                        Compliance
                      </Show>
                    </>
                  }
                  ariaLabel="Compliance"
                  navCollapsed={collapsed()}
                  animationComplete={animationComplete()}
                  activeForRoutes={["compliance/*", "compliance/applicant/*"]}
                >
                  <NavLink
                    navCollapsed={collapsed()}
                    href="/compliance/"
                    class="!pl-10"
                    activeForRoutes={["compliance/"]}
                    ariaLabel="Compliance"
                  >
                    <Show when={!collapsed() && animationComplete()}>
                      Alerts
                    </Show>
                  </NavLink>
                  <NavLink
                    navCollapsed={collapsed()}
                    href="/compliance/sponsored-hours/overview"
                    class="!pl-10"
                    activeForRoutes={["compliance/sponsored-hours/*"]}
                    ariaLabel="Compliance"
                  >
                    <Show when={!collapsed() && animationComplete()}>
                      Sponsored Hours
                    </Show>
                  </NavLink>
                </NavbarGroup>

                {/* Client Admin */}
                <ShowForFeatureFlag
                  key="ONBOARDING_WEB_ENABLE_AGENCY_LICENCE_INFO"
                  fallback={
                    <ShowForFeatureFlag key="ONBOARDING_WEB_ENABLE_SMS_QUESTIONNAIRE">
                      <NavLink
                        navCollapsed={collapsed()}
                        href="/questionnaire"
                        ariaLabel="Sponsor License Info"
                      >
                        <File02 class="w-6" />
                        <Show when={!collapsed() && animationComplete()}>
                          Sponsor License Info
                        </Show>
                      </NavLink>
                    </ShowForFeatureFlag>
                  }
                >
                  <ShowForRoles roles={[UserRoleType.Client]}>
                    <NavbarGroup
                      header={
                        <>
                          <Settings01 class="w-6" />
                          <Show when={!collapsed() && animationComplete()}>
                            Organisation
                          </Show>
                        </>
                      }
                      ariaLabel="Organisation"
                      navCollapsed={collapsed()}
                      animationComplete={animationComplete()}
                      activeForRoutes={["/agency/*"]}
                    >
                      <NavLink
                        navCollapsed={collapsed()}
                        class="!pl-10"
                        href="/agency/cos-requests"
                        ariaLabel="Settings"
                      >
                        CoS Requests
                      </NavLink>
                      <NavLink
                        navCollapsed={collapsed()}
                        class="!pl-10"
                        href="/agency/view"
                        ariaLabel="Settings"
                      >
                        Settings
                      </NavLink>
                    </NavbarGroup>
                  </ShowForRoles>
                </ShowForFeatureFlag>

                {/* Platform Admin */}
                <ShowForRoles roles={[UserRoleType.Admin]}>
                  <NavbarGroup
                    header={
                      <>
                        <ShieldTick class="w-6" />
                        <Show when={!collapsed() && animationComplete()}>
                          Admin
                        </Show>
                      </>
                    }
                    ariaLabel="Admin"
                    navCollapsed={collapsed()}
                    animationComplete={animationComplete()}
                    activeForRoutes={[
                      "/candidates/list/*",
                      "/agency/*",
                      "/meetings",
                    ]}
                  >
                    <NavLink
                      navCollapsed={collapsed()}
                      class="!pl-10"
                      href="/agency/list"
                      ariaLabel="Agencies"
                    >
                      Agencies
                    </NavLink>
                    <ShowForFeatureFlag key="ONBOARDING_WEB_ENABLE_SMS_QUESTIONNAIRE">
                      <NavLink
                        navCollapsed={collapsed()}
                        class="!pl-10"
                        href="/questionnaire"
                        ariaLabel="Sponsor License Info"
                      >
                        Sponsor License Info
                      </NavLink>
                    </ShowForFeatureFlag>
                    <NavLink
                      navCollapsed={collapsed()}
                      class="!pl-10"
                      href="/candidates/list"
                      ariaLabel="Candidates"
                    >
                      Candidates
                    </NavLink>
                    <NavLink
                      navCollapsed={collapsed()}
                      class="!pl-10"
                      href="/staged-candidate/list"
                      ariaLabel="Candidate screening"
                    >
                      Candidate screening
                    </NavLink>
                    <NavLink
                      class="!pl-10"
                      href="/meetings"
                      navCollapsed={collapsed()}
                      ariaLabel="Meetings"
                    >
                      Meetings
                    </NavLink>
                    <ShowForRoles roles={[UserRoleType.Admin]}>
                      <NavLink
                        class="!pl-10"
                        href="/sponsor-switch-request/list"
                        navCollapsed={collapsed()}
                        ariaLabel="Sponsor switch"
                      >
                        Sponsor Switch
                      </NavLink>
                    </ShowForRoles>
                    <ShowForRoles roles={[UserRoleType.Admin]}>
                      <NavLink
                        class="!pl-10"
                        href="/referrals/list"
                        navCollapsed={collapsed()}
                        ariaLabel="Referrals"
                      >
                        Referrals
                      </NavLink>

                      <NavLink
                        navCollapsed={collapsed()}
                        class="!pl-10"
                        href="/agency/cos-requests"
                        ariaLabel="Settings"
                      >
                        CoS Requests
                      </NavLink>
                    </ShowForRoles>
                  </NavbarGroup>
                </ShowForRoles>
              </div>

              <div class="mt-8 space-y-8">
                <Show
                  when={!collapsed() && animationComplete()}
                  fallback={
                    <div class="flex size-10 animate-background items-center justify-center rounded-full bg-gradient-to-br from-[#97F2FE] via-[#f8e0f7] to-[#97F2FE] text-gray-blue-700 shadow duration-500">
                      <Gift01 class="size-5" />
                    </div>
                  }
                >
                  <a
                    href="https://form.typeform.com/to/TwSyYzx7"
                    class="cursor-pointer"
                  >
                    <div class="space-y-2 rounded-lg border border-transparent bg-[#97F2FE] p-4 text-sm shadow-lg">
                      <p class="text-base font-medium text-primary-700">
                        £1,000 referral bonus
                      </p>
                      <p class="text-gray-blue-700">
                        <span class="underline underline-offset-2">
                          Click here
                        </span>{" "}
                        to refer a friend at another care provider to us.
                      </p>
                      <p class="text-gray-blue-700">
                        If they sign up, we'll show our appreciation by giving
                        you £1000 in cash or donating to a charity of your
                        choice!
                      </p>
                    </div>
                  </a>
                </Show>

                <div class="flex w-full items-center gap-2 text-white md:text-primary-900">
                  <UserAvatar
                    firstName={nameParts?.at(0)}
                    lastName={nameParts?.at(-1)}
                    size="sm"
                    class="shrink-0 border-white max-md:border"
                  />
                  <Show when={!collapsed() && animationComplete()}>
                    <div class="grow overflow-hidden text-sm">
                      <p
                        class="w-full overflow-hidden text-ellipsis font-semibold"
                        title={auth?.authenticatedUser()?.name}
                      >
                        {auth?.authenticatedUser()?.name}
                      </p>
                      <p
                        class="w-full overflow-hidden text-ellipsis"
                        title={auth?.authenticatedUser()?.email}
                      >
                        {auth?.authenticatedUser()?.email}
                      </p>
                    </div>

                    <Button
                      onClick={() => {
                        auth?.logout();
                      }}
                      color="icon"
                      class="self-end"
                    >
                      <LogOut01 class="w-5" />
                    </Button>
                  </Show>
                </div>

                <div
                  class="flex flex-wrap items-center justify-between gap-x-4 gap-y-2"
                  classList={{
                    hidden: collapsed(),
                  }}
                >
                  <button
                    class="text-sm text-white underline underline-offset-4 md:text-black"
                    onClick={() => {
                      document
                        .getElementById("termlyDisplayPreferences")
                        ?.click();
                    }}
                  >
                    Cookie preferences
                  </button>

                  <span class=" text-xs text-white md:text-gray-700">
                    v{APP_VERSION}
                    {isDevelopment() && (
                      <>&nbsp;#{import.meta.env.VITE_GIT_SHA}</>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Motion.div>
    </Show>
  );
};

export default Navbar;

declare const APP_VERSION: string;

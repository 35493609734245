import { useBeforeLeave, useParams } from "@solidjs/router";
import { createQuery } from "@tanstack/solid-query";
import { Component, ParentComponent } from "solid-js";

import { graphql } from "../../schema";
import gqlClient from "../../schema/gqlAuthorizedRequest";
import { UserRoleType } from "../../schema/graphql";
import { BackButton } from "../common/BackButton";
import { Layout } from "../common/Layout";
import { PageHeader } from "../common/PageHeader";
import PropertyDisplay from "../common/PropertyDisplay";
import { ShowForRoles } from "../common/ShowForRoles";
import {
  AgencySettingsContextProvider,
  useAgencySettings,
} from "./AgencySettingsContext";
import { AgencySettingsNav } from "./AgencySettingsNav";

const getAgency = graphql(`
  query agencySettingsRouter($id: Int) {
    getAgency(id: $id) {
      name
      dbsProviderOrganisationId
    }
  }
`);

const AgencySettingsHeaderRight: Component = () => {
  const agencySettings = useAgencySettings();

  useBeforeLeave(() => {
    agencySettings?.setHeaderContentRight(null);
  });

  return <div>{agencySettings?.headerContentRight()}</div>;
};

const AgencySettingsRouter: ParentComponent = (props) => {
  const agencyId = Number.parseInt(useParams().id, 10);

  const agency = createQuery(() => ({
    queryKey: ["agencySettings", agencyId, "router"],
    queryFn: () => gqlClient.request(getAgency, { id: agencyId }),
    get enabled() {
      return agencyId !== undefined;
    },
  }));

  return (
    <Layout>
      <AgencySettingsContextProvider>
        <div class="flex size-full flex-col">
          <ShowForRoles roles={[UserRoleType.Admin]}>
            <BackButton to="/agency/list" />
            <PageHeader
              title={agency.data?.getAgency.name}
              parentTitle="Admin"
              subtitle={
                <PropertyDisplay title="Disclosure Services Organisation ID: ">
                  {agency.data?.getAgency.dbsProviderOrganisationId}
                </PropertyDisplay>
              }
              class="mb-4"
            >
              <AgencySettingsHeaderRight />
            </PageHeader>
          </ShowForRoles>

          <ShowForRoles roles={[UserRoleType.Client]}>
            <PageHeader
              parentTitle="Organisation"
              title="Settings"
              subtitle="View and manage your organisation settings"
              class="mb-4"
            >
              <AgencySettingsHeaderRight />
            </PageHeader>
          </ShowForRoles>

          <div>
            <AgencySettingsNav />
          </div>

          <div class="w-full pt-4">{props.children}</div>
        </div>
      </AgencySettingsContextProvider>
    </Layout>
  );
};

export default AgencySettingsRouter;

import { A, useLocation } from "@solidjs/router";
import { Component, createMemo } from "solid-js";

import { Tab } from "./Tab";

export const TabLink: Component<{ href: string; pageTitle: string }> = (
  props,
) => {
  const location = useLocation();
  const isActive = createMemo(() => {
    if (props.href === "") {
      return /\/applicant\/profile\/\d+$/.test(location.pathname);
    }
    // sub routes
    if (location.pathname.includes("applicant/profile")) {
      // if we are in a profile route, check to see if sub route matches href of nav button
      return location.pathname.split(/profile(.*)/s)[1].includes(props.href);
    }
    // otherwise check for exact match
    return location.pathname.endsWith(props.href);
  });

  return (
    <A href={props.href} end={true}>
      <Tab active={isActive()}>
        <span class="whitespace-nowrap text-xs">{props.pageTitle}</span>
      </Tab>
    </A>
  );
};

import { XClose } from "@liftedcare/solidjs-untitled-icons/duocolor";
import {
  children,
  createContext,
  JSX,
  onCleanup,
  ParentComponent,
  Show,
  useContext,
} from "solid-js";
import { Portal } from "solid-js/web";

export interface IModalProps {
  show: boolean;
  onClose: () => void;
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "auto";
  dialogClass?: string;
  style?: JSX.CSSProperties;
}

const ModalContext = createContext<{
  onClose: () => void;
}>();

const Modal: ParentComponent<IModalProps> = (props) => {
  const handleEscapeKeyUp = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      props.onClose();
    }
  };
  document.addEventListener("keyup", handleEscapeKeyUp);

  onCleanup(() => {
    document.removeEventListener("keyup", handleEscapeKeyUp);
  });

  const onClose = () => props.onClose();

  return (
    <ModalContext.Provider value={{ onClose }}>
      <Show when={props.show}>
        <Portal>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div
            class="fixed inset-0 z-50 flex items-center justify-center bg-gray-500/60"
            // TODO: Should we remove this for better a11y?
            onMouseDown={(e) => {
              if (e.target === e.currentTarget) {
                props.onClose();
              }
            }}
          >
            <div
              role="dialog"
              aria-labelledby="modal-title"
              class={`mx-4 max-h-full rounded-lg bg-white shadow-2xl ${props.dialogClass}`}
              classList={{
                "w-full max-w-md": props.size === "xs",
                "w-full max-w-xl": props.size === "sm",
                "w-full max-w-2xl": !props.size || props.size === "md",
                "w-full max-w-4xl": props.size === "lg",
                "w-full max-w-6xl": props.size === "xl",
              }}
              style={props.style}
            >
              {children(() => props.children)()}
            </div>
          </div>
        </Portal>
      </Show>
    </ModalContext.Provider>
  );
};

export default Modal;

export const ModalHeader: ParentComponent<{
  icon?: JSX.Element;
  style?: "simple" | "rich";
}> = (props) => {
  const style = () => props.style ?? "simple";

  const modalContext = useContext(ModalContext);
  const title = (
    <h3
      id="modal-title"
      class="text-lg"
      classList={{
        "text-white": style() === "rich",
        "text-gray-700": style() === "simple",
      }}
    >
      {props.children}
    </h3>
  );
  return (
    <div
      class="rounded-t-lg p-5 pb-4"
      classList={{
        "border-b": style() === "simple",
        "bg-primary-700 bg-gradient-to-br from-primary-700 to-primary-500 text-white":
          style() === "rich",
      }}
    >
      <div class="flex items-center justify-between gap-2 rounded-t-lg">
        <Show when={props.icon} fallback={title}>
          {props.icon}
        </Show>
        <Show when={props.icon && props.children}>{title}</Show>
        <button
          type="button"
          class="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm transition-colors"
          onClick={() => modalContext?.onClose()}
          classList={{
            "text-white hover:bg-primary-400": style() === "rich",
            "text-gray-700 hover:bg-gray-200 hover:text-gray-900":
              style() === "simple",
          }}
        >
          <XClose class="size-6" />
          <span class="sr-only">Close modal</span>
        </button>
      </div>
    </div>
  );
};

export const ModalBody: ParentComponent<{ class?: string }> = (props) => {
  return (
    <div
      class={`${
        props.class ?? ""
      } max-h-[60svh] grow space-y-4 overflow-y-auto p-5`}
    >
      {props.children}
    </div>
  );
};

export const ModalFooter: ParentComponent<{ class?: string }> = (props) => {
  return (
    <div
      class={`${
        props.class ?? ""
      } flex items-center space-x-2 rounded-b-lg border-t border-gray-200 p-5`}
    >
      {props.children}
    </div>
  );
};

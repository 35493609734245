import { useNavigate } from "@solidjs/router";
import { ParentComponent } from "solid-js";

import { useAuth } from "../components/auth/AuthContext";
import { UserRoleType } from "../schema/graphql";

export const RouteGuard: ParentComponent<{ rolesAllowed: UserRoleType[] }> = (
  props,
) => {
  const auth = useAuth();
  const navigate = useNavigate();
  if (!auth?.authenticatedUser()) {
    navigate("/");
  }

  const rolesAllowed = () => props.rolesAllowed;

  if (
    !rolesAllowed().includes(auth?.authenticatedUser()?.role as UserRoleType)
  ) {
    navigate("/forbidden");
  }

  return <>{props.children}</>;
};

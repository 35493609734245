import { upperFirst } from "lodash-es";

interface NameObject {
  firstName?: string | null;
  lastName?: string | null;
}

export function getName(
  nameObject?: NameObject | null,
  fallback?: string | null,
): string;
export function getName(
  first?: string | null,
  last?: string | null,
  fallback?: string | null,
): string;
export function getName(
  nameObjectOrFirst?: NameObject | string | null,
  lastOrFallback?: string | null,
  fallback?: string | null,
) {
  let _first: string | null = null;
  let _last: string | null = null;
  let _fallback: string | null = null;

  if (typeof nameObjectOrFirst === "object") {
    _first = nameObjectOrFirst?.firstName ?? null;
    _last = nameObjectOrFirst?.lastName ?? null;
    _fallback = lastOrFallback ?? null;
  } else if (typeof nameObjectOrFirst === "string") {
    _first = nameObjectOrFirst;
    _last = lastOrFallback ?? null;
    _fallback = fallback ?? null;
  }

  if (!_first && !_last) {
    return _fallback ?? "Unknown";
  }

  return `${upperFirst(_first ?? "")} ${upperFirst(_last ?? "")}`;
}

import { AlertTriangle } from "@liftedcare/solidjs-untitled-icons/line";
import { Check, InfoCircle } from "@liftedcare/solidjs-untitled-icons/solid";
import { For, ParentComponent } from "solid-js";
import { createStore } from "solid-js/store";
import { Portal } from "solid-js/web";

import { IToastProps, Toast } from "./Toast";
import { ToastContext } from "./ToastContext";

// Create a random ID
function generateId() {
  const x = 2_147_483_648;
  return (
    Math.floor(Math.random() * x).toString(36) +
    Math.abs(Math.floor(Math.random() * x) ^ Date.now()).toString(36)
  );
}

const ToastIconContainer: ParentComponent<{
  type: "info" | "warning" | "error" | "success";
}> = (props) => (
  <div
    class="flex size-6 items-center justify-center rounded-full ring-8"
    classList={{
      "bg-primary-100 ring-primary-50": !props.type,
      "bg-blue-100 ring-blue-50": props.type === "info",
      "bg-warning-100 ring-warning-50": props.type === "warning",
      "bg-error-100 ring-error-50": props.type === "error",
      "bg-success-100 ring-success-50": props.type === "success",
    }}
  >
    {props.children}
  </div>
);

export const ToastProvider: ParentComponent = (props) => {
  const [toasts, setToasts] = createStore<IToastProps[]>([]);

  const open = (toast: Omit<IToastProps, "id">) => {
    const newToast: IToastProps = {
      id: generateId(),
      title: toast.title,
      content: toast.content,
      details: toast.details,
      iconComponent: toast.iconComponent,
      autoCloseMs: toast.autoCloseMs,
      closeable: toast.closeable,
      close: toast.close,
      className: toast.className,
      containerClassName: toast.containerClassName,
    };
    setToasts([...toasts, newToast]);
    return newToast.id;
  };

  const info = (
    title: string,
    content: string,
    details?: string,
    closeable?: boolean,
    autoCloseMs?: number,
    className?: string,
    containerClassName?: string,
  ) => {
    return open({
      title,
      content,
      details,
      iconComponent: (
        <ToastIconContainer type="info">
          <InfoCircle class="h-4 text-blue-500" />
        </ToastIconContainer>
      ),
      autoCloseMs,
      closeable: closeable ?? true,
      className: `${className} border-blue-300`,
      containerClassName,
    });
  };

  const warning = (
    title: string,
    content: string,
    details?: string,
    closeable?: boolean,
    autoCloseMs?: number,
    className?: string,
    containerClassName?: string,
  ) => {
    return open({
      title,
      content,
      details,
      iconComponent: (
        <ToastIconContainer type="warning">
          <AlertTriangle class="h-4 text-yellow-600" />
        </ToastIconContainer>
      ),
      autoCloseMs,
      closeable: closeable ?? true,
      className: `${className} border-warning-300`,
      containerClassName,
    });
  };

  const error = (
    title: string,
    content: string,
    details?: string,
    closeable?: boolean,
    autoCloseMs?: number,
    className?: string,
    containerClassName?: string,
  ) => {
    return open({
      title,
      content,
      details,
      iconComponent: (
        <ToastIconContainer type="error">
          <AlertTriangle class={`h-4 text-error-500`} />
        </ToastIconContainer>
      ),
      autoCloseMs,
      closeable: closeable ?? true,
      className: `${className} border-error-300`,
      containerClassName,
    });
  };

  const success = (
    title: string,
    content: string,
    details?: string,
    closeable?: boolean,
    autoCloseMs?: number,
    className?: string,
    containerClassName?: string,
  ) => {
    return open({
      title,
      content,
      details,
      iconComponent: (
        <ToastIconContainer type="success">
          <Check class="h-4 text-green-600" />
        </ToastIconContainer>
      ),
      autoCloseMs,
      closeable: closeable ?? true,
      className: `${className} border-success-300`,
      containerClassName,
    });
  };

  const close = (id?: string) => {
    if (!id) {
      return;
    }
    setToasts((currentToasts: IToastProps[]) =>
      currentToasts.filter((toast) => toast.id !== id),
    );
  };

  return (
    <ToastContext.Provider
      value={{ toasts, open, close, info, warning, error, success }}
    >
      {props.children}
      <Portal>
        <div
          class={`fixed bottom-5 right-5 z-[100] w-3/4 md:w-1/3 2xl:w-1/4 ${
            toasts.at(-1)?.containerClassName ?? ""
          }`}
        >
          <For each={toasts}>
            {(toast) => <Toast {...toast} close={() => close(toast.id)} />}
          </For>
        </div>
      </Portal>
    </ToastContext.Provider>
  );
};

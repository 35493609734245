export type Environment = "production" | "staging" | "uat" | "development";

export const getEnvironment = (): Environment => {
  const { hostname } = window.location;
  if (hostname === "localhost") {
    return "development";
  }
  if (/\bstaging\b/.test(hostname)) {
    return "staging";
  }
  if (/\buat\b/.test(hostname)) {
    return "uat";
  }
  return "production";
};

export const isDevelopment = () => getEnvironment() !== "production";

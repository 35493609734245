import { captureException } from "@sentry/browser";
import {
  Accessor,
  createContext,
  createSignal,
  onMount,
  ParentComponent,
  useContext,
} from "solid-js";

interface CookieConfig {
  cookies: { name: string }[];
  categories: string[];
}

function isCookieConfig(value: unknown): value is CookieConfig {
  return (
    typeof value === "object" &&
    value !== null &&
    "categories" in value &&
    Array.isArray(value.categories)
  );
}

export const CookieContext = createContext<{
  config: Accessor<CookieConfig | null>;
}>();

export const CookieContextProvider: ParentComponent = (props) => {
  const [config, setConfig] = createSignal<CookieConfig | null>(null);

  function onCookieWhitelistUpdated(event: Event) {
    if ("detail" in event && isCookieConfig(event.detail)) {
      loadCookieWhitelist(event.detail);
    }
  }

  function loadCookieWhitelist(config?: CookieConfig) {
    if (!config) {
      // fetch from local storage as set in index.html
      const cookieWhitelistString = window.localStorage.getItem(
        "cookieWhitelistByTermly",
      );
      if (!cookieWhitelistString) {
        return;
      }
      try {
        config = JSON.parse(cookieWhitelistString);
      } catch (error) {
        captureException(error, {
          extra: {
            message: "Failed to parse cookie whitelist",
          },
        });
        return;
      }
    }

    if (!config) {
      return;
    }

    setConfig(config);
  }

  onMount(() => {
    window.addEventListener("cookieWhitelistUpdated", onCookieWhitelistUpdated);
    loadCookieWhitelist();
  });

  return (
    <CookieContext.Provider value={{ config }}>
      {props.children}
    </CookieContext.Provider>
  );
};

export const useCookies = () => useContext(CookieContext);

import { RouteDefinition } from "@solidjs/router";
import { lazy } from "solid-js";

import { CandidateListLocation } from "../schema/graphql";
import { catchAll } from "./catch-all.routes";

const CandidateList = lazy(
  () => import("../components/candidates/CandidateList"),
);
const CandidateProfile = lazy(
  () => import("../components/candidates/CandidateProfile"),
);
const CandidateEditForm = lazy(
  () => import("../components/candidates/CandidateEditForm"),
);
const AdminCandidateList = lazy(
  () => import("../components/candidates/AdminCandidateView"),
);

export const candidatesRoutes: RouteDefinition[] = [
  {
    path: "/candidates",
    children: [
      catchAll("/candidates/sponsored-uk"),
      {
        path: "/sponsored-uk",
        component: () => (
          <CandidateList
            title="Sponsored UK"
            subTitle="Manage your pipeline of sponsored candidates already based in the UK"
            locationFilter={CandidateListLocation.UkSponsorshipRequired}
          />
        ),
      },
      {
        path: "/sponsored-overseas",
        component: () => (
          <CandidateList
            title="Sponsored Overseas"
            subTitle="Manage your pipeline of candidates looking to relocate to the UK and require sponsorship"
            locationFilter={CandidateListLocation.International}
          />
        ),
      },
      {
        path: "/non-sponsored",
        component: () => (
          <CandidateList
            title="Non-Sponsored"
            subTitle="Manage your pipeline of candidates who do not require sponsorship to work in the UK"
            locationFilter={CandidateListLocation.UkNoSponsorshipRequired}
          />
        ),
      },
      { path: "/:candidateId", component: CandidateProfile },
      {
        path: "/:onboardingUserId/edit",
        component: CandidateEditForm,
      },
      { path: "/list", component: AdminCandidateList },
    ],
  },
];

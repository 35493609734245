import { ParentComponent } from "solid-js";

export const FormLabel: ParentComponent<{ class?: string; for?: string }> = (
  props,
) => {
  return (
    <label
      for={props.for}
      class={`${props.class} whitespace-nowrap text-sm text-gray-600`}
    >
      {props.children}
    </label>
  );
};

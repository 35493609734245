import { isGqlRequestError } from "./isGqlRequestError";

export const getGqlErrorMessage = (error: unknown, fallback?: string) => {
  let message = fallback ?? (error as Error).message;
  if (isGqlValidationError(error)) {
    return (message = error.response.errors[0].extensions.validation
      .map((v) => v.errors.map((e) => e.message).join("\n"))
      .join("\n"));
  }
  if (isGqlRequestError(error) && error.response.errors) {
    message = error.response.errors.map((e) => e.message).join(", ");
  }
  return message;
};

interface GqlValidationError {
  response: {
    errors: {
      message: string;
      path: string[];
      extensions: {
        statusCode: number;
        errorCode: string;
        validation: {
          entityId: number;
          index: number;
          errors: {
            message: string;
            path: string[];
            code: string;
          }[];
        }[];
      };
    }[];
  };
}

function isGqlValidationError(error: unknown): error is GqlValidationError {
  const gqlErr = error as GqlValidationError;
  return (
    gqlErr.response !== undefined &&
    gqlErr.response.errors !== undefined &&
    gqlErr.response.errors[0].extensions !== undefined &&
    gqlErr.response.errors[0].extensions.validation !== undefined
  );
}

import * as Sentry from "@sentry/browser";
import { MultiProvider } from "@solid-primitives/context";
import { useLocation } from "@solidjs/router";
import { QueryClientProvider } from "@tanstack/solid-query";
import { getValue } from "firebase/remote-config";
import satisfies from "semver/functions/satisfies";
import {
  createSignal,
  ErrorBoundary,
  Match,
  onMount,
  ParentComponent,
  Show,
  Switch,
} from "solid-js";

import happyAnnouncement from "./assets/happy-announcement.svg";
import { AppUpdateBanner } from "./components/AppUpdateBanner";
import { AgencyContextProvider } from "./components/auth/AgencyContext";
import { AuthContextProvider, useAuth } from "./components/auth/AuthContext";
import { AdminAgencyContextProvider } from "./components/candidates/AdminAgencyContext";
import { Button } from "./components/common/Button";
import {
  FeatureFlagsContext,
  remoteConfig,
} from "./components/config/FeatureFlagsContext";
import {
  CookieContextProvider,
  useCookies,
} from "./components/CookieContextProvider";
import { Error } from "./components/Error";
import { LiftedLogo } from "./components/icons/LiftedLogo";
import Navbar from "./components/navbar/Navbar";
import { StagedCandidateFilterContextProvider } from "./components/staged-candidates/StagedCandidateFilterContext";
import { ToastProvider } from "./components/toast";
import { getEnvironment } from "./utils/getEnvironment";
import { getQueryClient } from "./utils/getQueryClient";
import { handleGqlError } from "./utils/handleGqlError";

const AppBody: ParentComponent = (props) => {
  const auth = useAuth();
  const cookies = useCookies();
  const location = useLocation();

  onMount(() => {
    if (Sentry.getClient() == null) {
      Sentry.init({
        dsn: "https://fbd2beb591cb4cae8ce60836de2dcf35@o413804.ingest.sentry.io/4504520896151554",
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration(),
        ],
        environment: getEnvironment(),
        enabled: getEnvironment() !== "development",
        release: `carer-onboarding-web@${APP_VERSION}`,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1,
        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.5,
        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1,
        beforeSend(event) {
          if (!cookies?.config()?.categories.includes("analytics")) {
            return null;
          }
          return event;
        },
      });
    }
  });

  const useMainLayout = () => {
    return (
      Boolean(auth?.authenticatedUser()) &&
      !location.pathname.startsWith("/register")
    );
  };

  return (
    <main class="h-screen">
      <Navbar />
      <div
        class="h-full overflow-hidden"
        classList={{
          "grid-cols-1": !auth?.authenticatedUser(),
          "grid-cols-1 md:pl-[72px] max-sm:pb-[50px]": useMainLayout(),
        }}
      >
        <div class="flex h-full flex-col overflow-y-scroll bg-gray-25">
          <div class="grow">
            <ErrorBoundary
              fallback={(err, reset) => {
                console.error(err);
                Sentry.captureException(err);
                return (
                  <section class="flex h-full items-center justify-center">
                    <div class="max-w-lg space-y-6 p-4 text-center">
                      <Switch
                        fallback={
                          <>
                            <Error error={err.toString()} />
                            <Button onClick={() => reset()}>Return</Button>
                          </>
                        }
                      >
                        <Match
                          when={[
                            "Importing a module script failed",
                            "Failed to fetch dynamically imported module",
                          ].some((moduleErr: string) =>
                            err.toString().includes(moduleErr),
                          )}
                        >
                          <img
                            src={happyAnnouncement}
                            class="mx-auto"
                            alt="New version available"
                            width={250}
                          />
                          <h1 class="text-xl ">New version available!</h1>
                          <p>Please refresh the page to update and continue.</p>
                          <div class="flex justify-center">
                            <Button onClick={() => window.location.reload()}>
                              Update
                            </Button>
                          </div>
                        </Match>
                      </Switch>
                    </div>
                  </section>
                );
              }}
            >
              {props.children}
            </ErrorBoundary>
          </div>
          <Show when={useMainLayout()}>
            <footer class="relative flex items-center justify-center px-4 py-6">
              <LiftedLogo class="w-60 md:w-72" />
            </footer>
          </Show>
        </div>
      </div>
    </main>
  );
};

const App: ParentComponent = (props) => {
  const [appUpdateAvailable, setAppUpdateAvailable] = createSignal(false);

  onMount(() => {
    checkForAppUpdate();
    setInterval(
      () => {
        checkForAppUpdate();
      },
      1000 * 60 * 15, // every 15 mins
    );
  });

  function checkForAppUpdate() {
    const enabled = getValue(
      remoteConfig,
      "ONBOARDING_WEB_ENABLE_UPDATE_BANNER",
    )?.asBoolean();
    if (!enabled) {
      return;
    }
    const latestAppVersion = getValue(
      remoteConfig,
      "ONBOARDING_WEB_LATEST_VERSION",
    );
    setAppUpdateAvailable(
      !satisfies(
        APP_VERSION,
        `>=${latestAppVersion?.asString() ?? APP_VERSION}`,
      ),
    );
  }

  const queryClient = getQueryClient((e) =>
    handleGqlError(e, false, "Unknown error in queryClient"),
  );

  return (
    <QueryClientProvider client={queryClient}>
      <MultiProvider
        values={[
          CookieContextProvider,
          FeatureFlagsContext,
          ToastProvider,
          AuthContextProvider,
          AgencyContextProvider,
          StagedCandidateFilterContextProvider,
          AdminAgencyContextProvider,
        ]}
      >
        <Show when={appUpdateAvailable()}>
          <AppUpdateBanner />
        </Show>
        <AppBody>{props.children}</AppBody>
      </MultiProvider>
    </QueryClientProvider>
  );
};

export default App;

declare const APP_VERSION: string;

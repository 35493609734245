import {
  CreateSubmitHandlerConfig,
  KnownHelpers,
  KnownStores,
  Paths,
} from "@felte/common";
import { get, isNil } from "lodash-es";
import {
  Accessor,
  Component,
  createContext,
  createEffect,
  createSignal,
  For,
  onCleanup,
  ParentComponent,
  useContext,
} from "solid-js";
import { createStore } from "solid-js/store";

import { ValidationIssue } from "../../schema/graphql";

export const FormContext = createContext<{
  getErrors: (name: string) => any;
  setData: (name: string, value: any) => void;
  getData: (name: string) => any;
  errors?: Accessor<{ [key: string]: string } | undefined>;
  getIssues: (name?: string) => ValidationIssue[];
  getAllValidationIssues: () => ValidationIssue[];
}>();

// this is copied from node_modules/@felte/solid/dist/esm/create-form.d.ts to solve the problem that these types are not accessible
type Obj = Record<string, any>;
export type Form<Data extends Obj> = {
  /** Action function to be used with the `use` directive on your `form` elements. */
  form: (node: HTMLFormElement) => {
    destroy: () => void;
  };
  /** Function to handle submit to be passed to the on:submit event. Not necessary if using the `form` action. */
  handleSubmit: (e?: Event) => void;
  /** Function that creates a submit handler. If a function is passed as first argument it overrides the default `onSubmit` function set in the `createForm` config object. */
  createSubmitHandler: (
    altConfig?: CreateSubmitHandlerConfig<Data>,
  ) => (e?: Event) => void;
};

// type Form = ReturnType<typeof createForm>;

interface IFormContextProviderProps {
  formMethods: Form<any> & KnownHelpers<any, Paths<any>> & KnownStores<any>;
  errors?: Accessor<{ [key: string]: string } | undefined>;
  validationIssues?: ValidationIssue[] | null;
}

export const FormContextProvider: ParentComponent<IFormContextProviderProps> = (
  props,
) => {
  const [formData, setFormData] = createStore<any>();
  const [formErrors, setFormErrors] = createStore<any>();

  const [issues, setIssues] = createSignal<Map<string, ValidationIssue[]>>(
    new Map(),
  );

  const errors = props.errors;

  const getAllValidationIssues = () => {
    return props.validationIssues ?? ([] as ValidationIssue[]);
  };

  createEffect(() => {
    const issues = getAllValidationIssues();
    const issuesByField = issues.reduce<Map<string, ValidationIssue[]>>(
      (acc, issue) => {
        for (const error of issue.errors) {
          if (!error.path) {
            continue;
          }
          const path = [issue.index, error.path.join(".")]
            .filter((part) => !isNil(part))
            .join(".");

          acc.set(path, [
            ...(acc.get(path) ?? []),
            {
              ...issue,
              errors: [error],
            },
          ]);
        }

        return acc;
      },
      new Map(),
    );
    setIssues(issuesByField);
  });

  const getIssues = (name?: string) => {
    if (!name) {
      return props.validationIssues ?? [];
    }

    /**
     * Field are named with prefix such as employmentHistory.... but validation issues have path without the root form name.
     * Issues are also indexed so computed name will consist of index and path to value.
     * Use endWith to match the final part of the path to the value to make sure we get all issues for the field.
     */
    const names = [...issues().keys()].filter((key) => {
      return name.endsWith(key);
    });

    return names.reduce<ValidationIssue[]>((acc, name) => {
      return [...acc, ...(issues().get(name) ?? [])];
    }, []);
  };

  const unsubscribeFormData = props.formMethods.data.subscribe((data) => {
    setFormData(data);
  });

  const unsubscribeFormErrors = props.formMethods.errors.subscribe((data) => {
    setFormErrors(data);
  });

  const getData = (name: string) => {
    return get(formData, name);
  };

  const setData = (name: string, value: any) => {
    if (Array.isArray(value)) {
      props.formMethods.unsetField(name);
      if (value.length) {
        value.map((v) => props.formMethods.addField(name, v));
      } else {
        props.formMethods.setData(name, null);
      }
    } else {
      props.formMethods.setData(name, value);
    }
  };

  const getErrors = (name: string) => {
    return get(formErrors, name);
  };

  onCleanup(() => {
    unsubscribeFormErrors();
    unsubscribeFormData();
  });

  return (
    <FormContext.Provider
      value={{
        getData,
        setData,
        getErrors,
        errors,
        getIssues,
        getAllValidationIssues,
      }}
    >
      {props.children}
    </FormContext.Provider>
  );
};
export function useForm() {
  return useContext(FormContext);
}

export function stripValidation<T extends { validation?: ValidationIssue[] }>(
  data: T[],
) {
  return data.map((entry) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { validation, ...rest } = entry;
    return rest;
  });
}

export const IssueDisplay: Component<{ issues?: ValidationIssue[] }> = (
  props,
) => {
  return (
    <For each={props.issues}>
      {(issue) => (
        <For each={issue.errors}>{(error) => <p>{error.message}</p>}</For>
      )}
    </For>
  );
};

import { RouteDefinition } from "@solidjs/router";
import { lazy } from "solid-js";

import { UserRoleType } from "../schema/graphql";
import { RouteGuard } from "./RouteGuard";

const SponsorSwitchRequestList = lazy(
  () =>
    import("../components/sponsor-switch-requests/SponsorSwitchRequestList"),
);
const SponsorSwitchRequestView = lazy(
  () =>
    import("../components/sponsor-switch-requests/SponsorSwitchRequestView"),
);

export const sponsorSwitchRoutes: RouteDefinition[] = [
  {
    path: "/sponsor-switch-request",
    children: [
      {
        path: "/list",
        component: () => (
          <RouteGuard rolesAllowed={[UserRoleType.Admin]}>
            <SponsorSwitchRequestList />
          </RouteGuard>
        ),
      },
      {
        path: "/:id",
        component: () => (
          <RouteGuard rolesAllowed={[UserRoleType.Admin]}>
            <SponsorSwitchRequestView />
          </RouteGuard>
        ),
      },
    ],
  },
];

export function getApiUrl(host = window?.location?.host ?? "") {
  let apiUrl: string = import.meta.env.VITE_APP_API;

  const env = /uat|staging/.exec(host);
  if (env) {
    apiUrl = `https://api.carer-onboarding.${env[0]}.liftedcare.com`;
  } else if (
    ["app.lifted-talent.com", "carer-onboarding.liftedcare.com"].includes(host)
  ) {
    apiUrl = "https://api.carer-onboarding.production.liftedcare.com";
  }

  if (!apiUrl) {
    throw new Error("API env var not set");
  }
  return apiUrl;
}

import { Component } from "solid-js";

import fixing from "../assets/fixing.svg";

export const Error: Component<{ error: string }> = (props) => {
  return (
    <section class="flex h-full items-center justify-center">
      <div class="max-w-lg space-y-6 p-4">
        <img src={fixing} class="mx-auto" alt="Broken computer" width={250} />
        <h1 class="text-center text-xl">Sorry, something went wrong</h1>
        <p>
          An unexpected issue has occurred. If this issue persists please
          contact the support team. The error details are below - the support
          team may ask you to share a screenshot of this page.
        </p>

        <pre class="max-h-[50vh] overflow-auto text-ellipsis whitespace-normal rounded-lg border bg-gray-200 p-2 text-left text-sm">
          {props.error}
        </pre>
      </div>
    </section>
  );
};

import {
  Component,
  createEffect,
  createMemo,
  createSignal,
  createUniqueId,
  JSX,
  JSXElement,
  mergeProps,
  Show,
} from "solid-js";

import { onKeyDown } from "../../utils/inputUtils";
import { IssueDisplay, useForm } from "./FormContext";

interface InputProps {
  value?: string | null;
  editable?: boolean;
  name?: string;
  onChange?: (value: string | number) => void;
  class?: string;
  containerClass?: string;
  labelClass?: string;
  label?: string | JSX.Element;
  type?: HTMLInputElement["type"];
  pattern?: string;
  step?: string;
  placeholder?: string;
  autocomplete?: string;
  submitOnEnter?: boolean;
  prefixIcon?: JSXElement;
  suffixElm?: JSXElement;
  hideValidation?: boolean;
  onKeyUp?: JSX.EventHandler<HTMLInputElement, KeyboardEvent>;
  id?: string;
}

const Input: Component<InputProps> = (unmergedProps) => {
  const props = mergeProps({ type: "text" }, unmergedProps);

  const [value, setValue] = createSignal<string | undefined | null>("");

  const commonClassName = createMemo(
    () =>
      `border rounded-md flex gap-2 bg-white ${
        props.editable === false ? "" : "p-2"
      } ${props.class}`,
  );
  const labelClass = createMemo(
    () => `text-gray-500 ${props.labelClass ?? ""}`,
  );

  const uniqueId = createUniqueId();
  const id = () => props.id ?? uniqueId;

  const formContext = useForm();

  createEffect(() => {
    if (formContext && props.name) {
      setValue(formContext.getData(props.name));
      return;
    }
    setValue(props.value);
  });

  const valueChanged = (value: string | number) => {
    let newValue = value;
    if (props.type === "number") {
      newValue = Number(value);
    }
    props.onChange?.(newValue);

    if (formContext && props.name) {
      return formContext.setData(props.name, newValue);
    }
  };

  const getFormErrors = () => {
    if (!props.name) {
      return false;
    }

    const errors = formContext?.getErrors(props.name);
    // sometimes this returns true, which means the field is valid
    if (errors && errors !== true) {
      return errors;
    }
    return formContext?.errors?.()?.[props.name] ?? false;
  };

  const relevantIssues = () => formContext?.getIssues(props.name);

  return (
    <div class={`${props.containerClass} flex w-full flex-col`}>
      <Show when={props.label}>
        <label for={id()} class={labelClass()}>
          {props.label}
        </label>
      </Show>
      <Show
        when={props.editable || props.editable === undefined}
        fallback={
          <div class={`border-transparent ${commonClassName()} text-gray-500`}>
            <Show when={props.prefixIcon}>{props.prefixIcon}</Show>{" "}
            {value() ?? ""}
          </div>
        }
      >
        <div
          class={`rounded-md ${commonClassName()}`}
          classList={{
            "border-error-500 outline-error-500":
              Boolean(getFormErrors()) || Boolean(relevantIssues()?.length),
          }}
        >
          <Show when={props.prefixIcon}>{props.prefixIcon}</Show>
          <input
            onKeyUp={(e) => props.onKeyUp?.(e)}
            onKeyDown={(e) => (props.submitOnEnter ? undefined : onKeyDown(e))}
            autocomplete={props.autocomplete}
            id={id()}
            pattern={props.pattern}
            step={props.step}
            placeholder={props.placeholder}
            type={props.type}
            name={formContext ? undefined : props.name}
            onInput={(e) => {
              valueChanged(e.currentTarget.value);
            }}
            value={value() || ""}
            class="w-full grow bg-transparent outline-none"
            classList={{
              "appearance-none": props.type === "number",
            }}
          />
          <Show when={props.suffixElm}>{props.suffixElm}</Show>
        </div>
      </Show>
      <Show when={relevantIssues() && !props.hideValidation}>
        <p class="text-sm text-red-500 lg:col-span-2 lg:col-start-2">
          <IssueDisplay issues={relevantIssues()} />
        </p>
      </Show>
      <Show when={getFormErrors() !== false && !props.hideValidation}>
        <p class="text-sm text-error-500 lg:col-span-2 lg:col-start-2">
          {getFormErrors()}
        </p>
      </Show>
    </div>
  );
};

export default Input;

import {
  Accessor,
  createContext,
  createSignal,
  JSX,
  ParentComponent,
  Show,
  useContext,
} from "solid-js";

const TableContext = createContext<{
  title: Accessor<string | undefined>;
}>();

export const Table: ParentComponent<{
  class?: string;
  tableClass?: string;
  titleClass?: string;
  title?: string;
  header?: JSX.Element;
}> = (props) => {
  const [title] = createSignal(props.title);

  if (props.title && props.header) {
    throw new Error("Table cannot have both a title and a header");
  }

  return (
    <TableContext.Provider value={{ title }}>
      <div
        class={`overflow-x-auto overflow-y-hidden rounded-lg border bg-white ${props.class}`}
      >
        <Show when={title()}>
          <h2 class={`${props.titleClass} p-6 text-lg text-primary-700`}>
            {props.title}
          </h2>
        </Show>
        <Show when={props.header}>{props.header}</Show>

        <table class={`w-full border-collapse ${props.tableClass}`}>
          {props.children}
        </table>
      </div>
    </TableContext.Provider>
  );
};

export const Tr: ParentComponent<{
  onClick?: JSX.EventHandler<HTMLTableRowElement, Event>;
  class?: string;
  classList?: Record<string, boolean>;
}> = (props) => {
  const classList = () => {
    const classes = {
      "cursor-pointer hover:bg-gray-100 ": Boolean(props.onClick),
    };
    if (props.classList) {
      Object.assign(classes, props.classList);
    }
    return classes;
  };

  return (
    <tr
      class={`${props.class} [&:last-child>td]:border-b-0`}
      classList={classList()}
      onClick={(e) => props.onClick?.(e)}
    >
      {props.children}
    </tr>
  );
};

export const Th: ParentComponent<{
  class?: string;
  colspan?: number;
  onClick?: JSX.EventHandler<HTMLTableCellElement, Event>;
}> = (props) => {
  const tableContext = useContext(TableContext);
  return (
    <th
      class={`${props.class} border-b bg-gray-50 p-5 text-left text-xs font-normal text-gray-500`}
      classList={{
        "border-t": Boolean(tableContext?.title()),
        "cursor-pointer": Boolean(props.onClick),
      }}
      colspan={props.colspan}
      onClick={props.onClick}
    >
      {props.children}
    </th>
  );
};

export const Td: ParentComponent<{
  class?: string;
  colspan?: number;
  classList?: Record<string, boolean>;
}> = (props) => {
  const classList = () => {
    const classes = {};
    if (props.classList) {
      Object.assign(classes, props.classList);
    }
    return classes;
  };
  return (
    <td
      class={`${props.class} border-b border-b-gray-100 px-5 py-3 align-middle text-sm text-gray-500`}
      classList={classList()}
      colspan={props.colspan}
    >
      {props.children}
    </td>
  );
};

import "@dotlottie/player-component";

import Intercom from "@intercom/messenger-js-sdk";
import * as Sentry from "@sentry/browser";
import { useLocation, useNavigate } from "@solidjs/router";
import { useQueryClient } from "@tanstack/solid-query";
import { getAnalytics, setUserProperties } from "firebase/analytics";
import { getAuth, onAuthStateChanged, ParsedToken, User } from "firebase/auth";
import {
  Accessor,
  createContext,
  createEffect,
  createSignal,
  ParentComponent,
  Show,
  useContext,
} from "solid-js";

import { FirebaseApp } from "../../../firebaseConfig";
import loadingSquareLottie from "../../assets/lottie/loading.lottie";
import { graphql } from "../../schema";
import gqlClient from "../../schema/gqlAuthorizedRequest";
import { PermissionKeyEnum, UserRoleType } from "../../schema/graphql";
import { getEnvironment } from "../../utils/getEnvironment";
import { useCookies } from "../CookieContextProvider";
import { UserNotRegisteredModal } from "./UserNotRegisteredModal";
import VerifyYourEmail from "./VerifyYourEmail";

type UserTokenType = ParsedToken & {
  role: UserRoleType;
  permissionKeys: PermissionKeyEnum[];
  agencyId: number;
  id: number;
  name?: string;
  email?: string;
  email_verified: boolean;
};

export const AuthContext = createContext<{
  login: (user: User | null, isManualSignIn?: boolean) => void;
  logout: () => void;
  authenticatedUser: Accessor<UserTokenType | null>;
  refreshData: () => void;
}>();

const signInQuery = graphql(`
  mutation signIn($logSignIn: Boolean!) {
    signIn(logSignIn: $logSignIn) {
      success
    }
  }
`);

function tokenHasRole(token: ParsedToken): token is UserTokenType {
  return !!token.role;
}

export const AuthContextProvider: ParentComponent = (props) => {
  const auth = getAuth();
  const cookies = useCookies();

  const [loading, setLoading] = createSignal(true);
  const [showNotRegisteredModal, setShowNotRegisteredModal] =
    createSignal(false);
  const [authenticatedUser, setAuthenticatedUser] =
    createSignal<UserTokenType | null>(null);

  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();

  const ensureRegistered = async (
    isManualSignIn = false,
  ): Promise<UserTokenType | null> => {
    if (!auth.currentUser) {
      return null;
    }
    try {
      const result = await gqlClient.request(signInQuery, {
        logSignIn: isManualSignIn,
      });

      if (result.signIn.success) {
        queryClient.invalidateQueries({
          queryKey: ["userAgencyOnboarding"],
        });
        // Sign up complete, refresh token
        const tokenResult = await auth.currentUser.getIdTokenResult(true);

        if (getEnvironment() === "development") {
          console.table({
            token: tokenResult.token,
            role: tokenResult.claims.role,
            id: tokenResult.claims.id,
            agencyId: tokenResult.claims.agencyId,
          });

          console.debug(
            "User has permissions",
            (tokenResult.claims.permissionKeys as string[]).join(", "),
          );
        }

        if (!tokenHasRole(tokenResult.claims)) {
          throw new Error("User does not have a role");
        }

        Sentry.setUser({
          id: tokenResult.claims.id.toString(),
          role: tokenResult.claims.role,
        });
        if (
          getEnvironment() === "production" &&
          tokenResult.claims.role !== UserRoleType.Admin.toLowerCase() &&
          cookies?.config()?.categories.includes("analytics")
        ) {
          window.sessionRewind.startSession();
          window.sessionRewind.identifyUser({
            userId: tokenResult.claims.id.toString(),
            role: tokenResult.claims.role,
          });
        }
        Intercom({
          app_id: "m7223fty",
          user_id: tokenResult.claims.id.toString(),
          name: tokenResult.claims.name,
          email: tokenResult.claims.email,
        });

        const analytics = getAnalytics(FirebaseApp);
        setUserProperties(analytics, {
          role: tokenResult.claims.role,
          agencyId: tokenResult.claims.agencyId,
        });

        return {
          ...tokenResult.claims,
          id: tokenResult.claims.id,
          role: tokenResult.claims.role.toUpperCase() as UserRoleType,
          permissionKeys: tokenResult.claims.permissionKeys,
          agencyId: tokenResult.claims.agencyId,
        };
      } else {
        return null;
      }
    } catch {
      auth.signOut();
      setShowNotRegisteredModal(true);
      return null;
    }
  };

  const login = async (user: User | null, isManualSignIn = false) => {
    if (user) {
      const registeredUserData = await ensureRegistered(isManualSignIn);
      setAuthenticatedUser(registeredUserData);
    } else {
      await auth.signOut();
      setAuthenticatedUser(null);
    }
  };

  const refreshData = async () => {
    if (auth.currentUser) {
      const tokenResult = await auth.currentUser.getIdTokenResult(true);
      if (!tokenHasRole(tokenResult.claims)) {
        throw new Error("User does not have a role");
      }

      setAuthenticatedUser({
        ...tokenResult.claims,
        id: tokenResult.claims.id,
        role: tokenResult.claims.role.toUpperCase() as UserRoleType,
        permissionKeys: tokenResult.claims.permissionKeys,
        agencyId: tokenResult.claims.agencyId,
      });
    }
  };

  const logout = () => {
    login(null);
  };

  onAuthStateChanged(auth, (user) => {
    if (user) {
      login(user).then(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  });

  createEffect(() => {
    const inLoginRelatedPath = [
      "/register",
      "/login",
      "/forgotten-password",
    ].includes(location.pathname);
    if (!inLoginRelatedPath && !authenticatedUser() && !loading()) {
      navigate("/login");
      return;
    }

    if (inLoginRelatedPath && authenticatedUser()) {
      navigate("/");
      return;
    }
  });

  return (
    <AuthContext.Provider
      value={{ authenticatedUser, login, logout, refreshData }}
    >
      <Show
        when={!loading()}
        fallback={
          <div class="flex size-full items-center justify-center">
            <dotlottie-player
              autoplay
              loop
              mode="normal"
              src={loadingSquareLottie}
              class="w-full md:w-1/3"
            />
          </div>
        }
      >
        <Show
          when={!authenticatedUser() || authenticatedUser()?.email_verified}
          fallback={<VerifyYourEmail />}
        >
          {props.children}
        </Show>
      </Show>
      <UserNotRegisteredModal
        show={showNotRegisteredModal()}
        onClose={() => setShowNotRegisteredModal(false)}
      />
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

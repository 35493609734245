import { getIdToken } from "firebase/auth";
import { GraphQLClient, RequestMiddleware } from "graphql-request";

import { getFirebaseAuth } from "../utils/getFirebaseAuth";
import { getGqlEndpoint } from "../utils/getGqlEndpoint";
import { handleGqlError } from "../utils/handleGqlError";

const authMiddleware: RequestMiddleware = async (request) => {
  const auth = getFirebaseAuth();
  const token = auth.currentUser ? await getIdToken(auth.currentUser) : null;

  const headers = new Headers(request.headers);
  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }

  return {
    ...request,
    headers,
  };
};

const gqlClient = new GraphQLClient(getGqlEndpoint(), {
  requestMiddleware: authMiddleware,
  responseMiddleware: async (response) => {
    handleGqlError(response, true, "Unknown request error in gqlClient");
  },
});

export default gqlClient;

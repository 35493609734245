import { ChevronDown } from "@liftedcare/solidjs-untitled-icons/duocolor";
import { useMatch } from "@solidjs/router";
import {
  createEffect,
  createMemo,
  createSignal,
  JSX,
  ParentComponent,
  Show,
} from "solid-js";

export const NavbarGroup: ParentComponent<{
  header: JSX.Element;
  ariaLabel: string;
  expanded?: boolean;
  navCollapsed: boolean;
  activeForRoutes?: string[];
  animationComplete: boolean;
}> = (props) => {
  const isActive = createMemo(() => {
    if (props.activeForRoutes?.length) {
      return props.activeForRoutes?.some((r) => useMatch(() => r)());
    }
    return false;
  });

  const [expanded, setExpanded] = createSignal(props.expanded);

  createEffect(() => {
    if (props.navCollapsed) {
      setExpanded(false);
    }
  });

  return (
    <div>
      <button
        onClick={() => setExpanded(!expanded())}
        class="relative flex w-full gap-2 rounded-md p-2 align-middle text-white hover:bg-primary-400"
        classList={{
          "bg-primary-600": !expanded() && Boolean(isActive()),
        }}
        aria-label={props.ariaLabel}
      >
        {props.header}
        <Show when={!props.navCollapsed && props.animationComplete}>
          <ChevronDown
            class={`ml-auto size-5 transition-all ${
              expanded() ? "rotate-180" : ""
            }`}
          />
        </Show>
      </button>
      <Show when={expanded()}>
        <div class="space-y-1">{props.children}</div>
      </Show>
    </div>
  );
};

import { RouteDefinition } from "@solidjs/router";
import { lazy } from "solid-js";

export const stagedCandidateRoutes: RouteDefinition[] = [
  {
    path: "/staged-candidate",
    children: [
      {
        path: "/list",
        component: lazy(
          () => import("../components/staged-candidates/StagedCandidateList"),
        ),
      },
      {
        path: "/edit",
        component: lazy(
          () => import("../components/staged-candidates/StagedCandidateForm"),
        ),
      },
      {
        path: "/:id",
        component: lazy(
          () => import("../components/staged-candidates/StagedCandidateForm"),
        ),
      },
    ],
  },
];

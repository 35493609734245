import { Stars02 } from "@liftedcare/solidjs-untitled-icons/duocolor";
import { Component } from "solid-js";

import { Button } from "./common/Button";

export const AppUpdateBanner: Component = () => {
  return (
    <div class="absolute inset-x-0 top-0 z-[100]">
      <div class="mx-auto mt-4 flex  w-[95svw] items-center justify-between gap-2 rounded-lg bg-primary-700 p-3 text-center text-sm text-white shadow-xl">
        <div class="flex items-center gap-2">
          <div class="rounded-lg bg-primary-600 p-3">
            <Stars02 class="h-5" />
          </div>
          A new version of the app is available. Please refresh the page to
          update
        </div>

        <Button
          onClick={() => {
            window.location.reload();
          }}
          class="bg-white font-medium !text-primary-700 hover:!bg-gray-100"
        >
          Refresh
        </Button>
      </div>
    </div>
  );
};

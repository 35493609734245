import { JSX, ParentComponent, Show } from "solid-js";

import { PermissionKeyEnum, UserRoleType } from "../../schema/graphql";
import { useAuth } from "../auth/AuthContext";

export const ShowForRoles: ParentComponent<{
  roles?: UserRoleType[];
  permissions?: PermissionKeyEnum[];
  fallback?: JSX.Element;
}> = (props) => {
  const auth = useAuth();

  const hasAccess = () => {
    if (props.roles?.length && props.permissions?.length) {
      throw new Error(
        "ShowForRoles can only be enabled for roles or permissions, not both",
      );
    }

    if (props.roles) {
      const role = auth!.authenticatedUser()?.role;
      return role && props.roles.includes(role);
    }
    if (props.permissions) {
      const permissions = auth!.authenticatedUser()?.permissionKeys ?? [];
      return props.permissions.every((permission) =>
        permissions.includes(permission),
      );
    }
  };

  return (
    <Show when={hasAccess()} fallback={props.fallback}>
      {props.children}
    </Show>
  );
};

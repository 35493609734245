import { RouteDefinition } from "@solidjs/router";
import { lazy } from "solid-js";

import AgencySettingsRouter from "../components/agencies/AgencySettingsRouter";
import { UserRoleType } from "../schema/graphql";
import { catchAll } from "./catch-all.routes";
import { RouteGuard } from "./RouteGuard";

const AgencyList = lazy(() => import("../components/agencies/AgencyList"));
const SmsQuestionnaireContainer = lazy(
  () => import("../components/agencies/SmsQuestionnaireContainer"),
);
const CosRequestsContainer = lazy(
  () => import("../components/agencies/CosRequestsContainer"),
);
const AgencyProfileView = lazy(
  () => import("../components/agencies/AgencySettingsAppProfile"),
);
const AgencyRecruitmentCriteriaView = lazy(
  () => import("../components/agencies/AgencyRecruitmentCriteria"),
);

const agencySettingsChildren = (parent: string) => [
  catchAll(`${parent}/addresses`),
  {
    path: "/users",
    component: lazy(
      () => import("../components/agencies/AgencySettingsUserList"),
    ),
  },
  {
    path: "/addresses",
    component: lazy(
      () => import("../components/agencies/AgencySettingsAddressList"),
    ),
  },
  {
    path: "/recruitment-criteria",
    component: lazy(
      () => import("../components/agencies/AgencySettingsRecruitmentCriteria"),
    ),
  },
  {
    path: "/job-descriptions",
    component: lazy(
      () => import("../components/agencies/AgencySettingsJobDescriptionsList"),
    ),
  },
  {
    path: "/sponsor-licence",
    component: lazy(
      () =>
        import("../components/agencies/AgencySettingsSponsorLicenceDetails"),
    ),
  },
  {
    path: "/candidates",
    component: lazy(
      () => import("../components/agencies/AgencySettingsCandidates"),
    ),
  },
  {
    path: "/e-signatures",
    component: lazy(
      () => import("../components/agencies/AgencySettingsESignatureConfig"),
    ),
  },
  {
    path: "/app-profile",
    component: lazy(
      () => import("../components/agencies/AgencySettingsAppProfile"),
    ),
  },
  {
    path: "/sponsor-licence-application",
    component: lazy(
      () => import("../components/agencies/SmsQuestionnaireContainer"),
    ),
  },
];

export const agencyRoutes: RouteDefinition[] = [
  {
    path: "/agency",
    children: [
      {
        path: "/:id/view/*",
        component: AgencySettingsRouter,
        children: agencySettingsChildren("/agency/:id/view"),
      },
      {
        path: "/view/*",
        component: AgencySettingsRouter,
        children: agencySettingsChildren("/agency/view"),
      },
      {
        path: "/recruitment-criteria",
        component: () => (
          <RouteGuard rolesAllowed={[UserRoleType.Client]}>
            <AgencyRecruitmentCriteriaView />
          </RouteGuard>
        ),
      },
      {
        path: "/:id/profile",
        component: () => (
          <RouteGuard rolesAllowed={[UserRoleType.Admin]}>
            <AgencyProfileView />
          </RouteGuard>
        ),
      },
      {
        path: "/profile",
        component: () => (
          <RouteGuard rolesAllowed={[UserRoleType.Client]}>
            <AgencyProfileView />
          </RouteGuard>
        ),
      },
      {
        path: "/cos-requests",
        component: () => (
          <RouteGuard rolesAllowed={[UserRoleType.Client, UserRoleType.Admin]}>
            <CosRequestsContainer />
          </RouteGuard>
        ),
      },
      {
        path: "/list",
        component: () => (
          <RouteGuard rolesAllowed={[UserRoleType.Admin]}>
            <AgencyList />
          </RouteGuard>
        ),
      },
    ],
  },
  {
    path: "/questionnaire",
    component: () => (
      <RouteGuard rolesAllowed={[UserRoleType.Admin, UserRoleType.Client]}>
        <SmsQuestionnaireContainer />
      </RouteGuard>
    ),
  },
];

import { RouteDefinition } from "@solidjs/router";
import { lazy } from "solid-js";

import { catchAll } from "./catch-all.routes";

export const complianceRoutes: RouteDefinition[] = [
  {
    path: "/compliance",
    children: [
      catchAll("/compliance/overview"),
      {
        path: "/overview",
        component: lazy(
          () => import("../components/compliance/ComplianceOverview"),
        ),
      },
      {
        path: "/sponsored-hours",
        children: [
          {
            path: "/overview",
            component: lazy(
              () => import("../components/compliance/SponsoredHoursOverview"),
            ),
          },
          {
            path: "/:id",
            component: lazy(
              () => import("../components/compliance/SponsoredHoursDetails"),
            ),
          },
        ],
      },
    ],
  },
];

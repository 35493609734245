import { JSXElement, ParentComponent, Show, useContext } from "solid-js";

import { FlagsContext } from "./FeatureFlagsContext";

export const ShowForFeatureFlag: ParentComponent<{
  key: string;
  fallback?: JSXElement;
}> = (props) => {
  const featureFlags = useContext(FlagsContext);

  return (
    <Show
      when={featureFlags?.flags()[props.key]?.asBoolean()}
      fallback={props.fallback}
    >
      {props.children}
    </Show>
  );
};

import { ParentComponent } from "solid-js";

export const Tab: ParentComponent<{
  class?: string;
  active: boolean;
  activeClass?: string;
  onClick?: () => void;
}> = (props) => {
  return (
    <button
      class={`${props.class} cursor-pointer`}
      classList={{
        [props.activeClass ??
        "text-primary-800 underline decoration-2 underline-offset-[13px]"]:
          props.active,
      }}
      onClick={() => props.onClick?.()}
    >
      {props.children}
    </button>
  );
};

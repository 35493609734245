import { Component } from "solid-js";

import { UserRoleType } from "../../schema/graphql";
import { ShowForRoles } from "../common/ShowForRoles";
import { TabLink } from "../tabs/TabLink";
import { Tabs } from "../tabs/Tabs";

export const AgencySettingsNav: Component = () => {
  return (
    <div class="w-full overflow-x-auto">
      <Tabs class="gap-3 border-b-0">
        <TabLink href="addresses" pageTitle="Addresses" />
        <TabLink href="app-profile" pageTitle="App Profile" />
        <ShowForRoles roles={[UserRoleType.Admin]}>
          <TabLink href="candidates" pageTitle="Candidates" />
          <TabLink href="e-signatures" pageTitle="eSignature Config" />
        </ShowForRoles>
        <TabLink href="job-descriptions" pageTitle="Job Descriptions" />
        <TabLink href="recruitment-criteria" pageTitle="Recruitment Criteria" />
        <TabLink href="sponsor-licence" pageTitle="Sponsor Licence" />
        <TabLink
          href="sponsor-licence-application"
          pageTitle="Sponsor Licence Application"
        />
        <TabLink href="users" pageTitle="Users" />
      </Tabs>
    </div>
  );
};

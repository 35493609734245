import { createContext } from "solid-js";

import { IToastProps } from "./Toast";

export interface IToastContext {
  open: (toast: Omit<IToastProps, "id">) => string | undefined;
  info: (
    title: string,
    content: string,
    details?: string,
    closeable?: boolean,
    timerMs?: number,
  ) => string;
  warning: (
    title: string,
    content: string,
    details?: string,
    closeable?: boolean,
    timerMs?: number,
  ) => string;
  error: (
    title: string,
    content: string,
    details?: string,
    closeable?: boolean,
    timerMs?: number,
  ) => string;
  success: (
    title: string,
    content: string,
    details?: string,
    closeable?: boolean,
    timerMs?: number,
  ) => string;
  close: (id: string) => void;
  toasts: IToastProps[];
}

export const ToastContext = createContext<IToastContext>();

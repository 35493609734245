import { Check } from "@liftedcare/solidjs-untitled-icons/duocolor";
import { createMemo, JSX, ParentComponent, Show } from "solid-js";

import { IssueDisplay, useForm } from "./FormContext";

export interface ICheckboxProps {
  value?: boolean;
  onChange?: (newValue: boolean) => void;
  onMouseMove?: (e: MouseEvent) => void;
  name?: string;
  disabled?: boolean;
  id?: string;
  arialLabel?: string;
  hideValidation?: boolean;
  label?: string | JSX.Element;
  labelClass?: string;
  inline?: boolean;
}

export const Checkbox: ParentComponent<ICheckboxProps> = (
  props: ICheckboxProps,
) => {
  const formContext = useForm();

  const inline = () => {
    if (props.inline != null) {
      return props.inline;
    }
    return true;
  };

  const getValue = () => {
    if (formContext && props.name) {
      return formContext.getData(props.name);
    }
    return props.value;
  };

  const changeValue = () => {
    if (props.disabled) {
      return;
    }
    const currentVal = getValue();

    props.onChange?.(!currentVal);

    if (formContext && props.name) {
      return formContext.setData(props.name, !currentVal);
    }
  };

  const onKeyDownHandler = (event: KeyboardEvent) => {
    if (event.key === " ") {
      changeValue();
      event.preventDefault();
    }
  };

  const getFormErrors = () => {
    if (!props.name) {
      return false;
    }

    const errors = formContext?.getErrors(props.name);
    // sometimes this returns true, which means the field is valid
    if (errors && errors !== true) {
      return errors;
    }
    return formContext?.errors?.()?.[props.name] ?? false;
  };

  const relevantIssues = () => formContext?.getIssues(props.name);

  const labelClass = createMemo(
    () => `text-gray-500 cursor-pointer ${props.labelClass ?? ""}`,
  );

  const label = () => (
    <Show when={props.label}>
      <button type="button" onClick={changeValue}>
        <label class={labelClass()} for={props.id}>
          {props.label}
        </label>
      </button>
    </Show>
  );

  return (
    <>
      <div classList={{ "flex flex-row items-center gap-2": inline() }}>
        <Show when={!inline()}>{label()}</Show>
        <button
          class={`flex size-5 cursor-pointer items-center justify-center rounded border border-gray-300 disabled:cursor-not-allowed disabled:opacity-50`}
          classList={{
            "border-primary-500": getValue(),
          }}
          type="button"
          onClick={changeValue}
          tabIndex={0}
          onKeyDown={onKeyDownHandler}
          id={props.id}
          aria-label={props.arialLabel}
          disabled={props.disabled}
          onMouseMove={(e) => {
            props.onMouseMove?.(e);
          }}
        >
          <Show when={getValue()}>
            <Check class="size-4 text-primary-500" />
          </Show>
        </button>
        <Show when={inline()}>{label()}</Show>
      </div>
      <Show when={relevantIssues() && !props.hideValidation}>
        <p class="text-sm text-red-500 lg:col-span-2 lg:col-start-2">
          <IssueDisplay issues={relevantIssues()} />
        </p>
      </Show>
      <Show when={getFormErrors() !== false && !props.hideValidation}>
        <p class="text-sm text-error-500 lg:col-span-2 lg:col-start-2">
          {getFormErrors()}
        </p>
      </Show>
    </>
  );
};

import { useContext } from "solid-js";

import { IToastContext, ToastContext } from "./ToastContext";

/**
 * use like:
 * import { useToast } from '../common/Toast';
 * const toast = useToast();
 * toast.error("My err title", "My err content...");
 */
export const useToast = () => useContext(ToastContext) as IToastContext;

import { createContext, ParentComponent, useContext } from "solid-js";
import { createStore } from "solid-js/store";

import {
  ApplicantSourceType,
  ContractTypeType,
  ScreeningRightToWorkStatus,
  StagedCandidateFilterCarAccess,
  StagedCandidateFilterDrivingLicence,
  StagedCandidateFilterPriority,
  StagedCandidateStageType,
  WorkAvailabilityV2,
} from "../../schema/graphql";

export interface IFilters {
  carAccess?: StagedCandidateFilterCarAccess;
  drivingLicence?: StagedCandidateFilterDrivingLicence;
  priority?: StagedCandidateFilterPriority;
  rightToWorkStatus?: ScreeningRightToWorkStatus;
  contractType?: ContractTypeType[];
  willingnessToRelocate?: boolean;
  yearsOfExperience?: number;
  availabilityV2?: WorkAvailabilityV2[];
  stage?: StagedCandidateStageType;
  importedAfter?: string;
  importedBefore?: string;
  agencyId?: number;
  source?: ApplicantSourceType;
  searchQuery?: string;
}

export const CandidateFilterContext = createContext<{
  getFilters: () => any;
  setFilters: (filters: IFilters) => void;
  getDefaultFilters: () => any;
}>();

const FILTER_KEY = "candidateFilters";

const DEFAULT_FILTERS = {
  carAccess: StagedCandidateFilterCarAccess.NoPreference,
  drivingLicence: StagedCandidateFilterDrivingLicence.NoPreference,
  priority: StagedCandidateFilterPriority.All,
  rightToWorkStatus: null,
  contractType: [],
  willingnessToRelocate: null,
  yearsOfExperience: 0,
  availabilityV2: [],
  stage: null,
  importedAfter: null,
  importedBefore: null,
  agencyId: null,
  source: null,
  searchQuery: "",
  outcode: "",
};

const readStorage = () => {
  try {
    return Object.assign(
      {},
      DEFAULT_FILTERS,
      JSON.parse(window.sessionStorage.getItem(FILTER_KEY) || "{}"),
    );
  } catch {
    return DEFAULT_FILTERS;
  }
};

const getDefaultFilters = () => {
  return DEFAULT_FILTERS;
};

export const StagedCandidateFilterContextProvider: ParentComponent = (
  props,
) => {
  const [filtersStore, setFiltersStore] = createStore<IFilters>(readStorage());

  const setFilters = (filters: IFilters) => {
    window.sessionStorage.setItem(FILTER_KEY, JSON.stringify(filters));
    setFiltersStore(filters);
  };

  const getFilters = () => {
    const filters: IFilters = Object.assign({}, DEFAULT_FILTERS, filtersStore);
    return filters;
  };

  return (
    <CandidateFilterContext.Provider
      value={{ setFilters, getFilters, getDefaultFilters }}
    >
      {props.children}
    </CandidateFilterContext.Provider>
  );
};
export function useCandidateFilters() {
  return useContext(CandidateFilterContext);
}

import { useNavigate } from "@solidjs/router";
import { Component } from "solid-js";

import notfound from "../assets/notfound.svg";
import { Button } from "./common/Button";

export const NotFound: Component = () => {
  const navigate = useNavigate();
  return (
    <section class="flex h-full items-center justify-center">
      <div class="max-w-lg space-y-6 p-4">
        <img src={notfound} class="mx-auto" alt="Broken computer" width={250} />
        <h1 class="text-center text-xl">404 Page not found</h1>
        <p class="text-center">
          Sorry, this page does not exist! Please check the URL, or hit back to
          return to the previous page.
        </p>
        <Button class="w-full" onClick={() => navigate(-1)}>
          Go back
        </Button>
      </div>
    </section>
  );
};

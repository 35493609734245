import { useNavigate } from "@solidjs/router";
import { type Component, useContext } from "solid-js";

import { AgencyStatus, UserRoleType } from "../schema/graphql";
import { useAgencyContext } from "./auth/AgencyContext";
import { useAuth } from "./auth/AuthContext";
import { FlagsContext } from "./config/FeatureFlagsContext";

export const Main: Component = () => {
  const agencyContext = useAgencyContext();
  const navigate = useNavigate();
  const featureFlags = useContext(FlagsContext);
  const auth = useAuth();

  const authUser = auth?.authenticatedUser();
  const status = agencyContext?.agency()?.status;

  if (
    authUser?.role === UserRoleType.Client &&
    status &&
    status === AgencyStatus.Onboarding
  ) {
    navigate("/register/agency");
  } else if (featureFlags?.flags().ONBOARDING_WEB_SELF_SERVE?.asBoolean()) {
    navigate("/candidates/sponsored-uk");
  } else {
    navigate("/applicants/compliance-in-progress");
  }

  return <p class={"text-primary-500"}>Loading</p>;
};

import { ParentComponent } from "solid-js";

export const Tabs: ParentComponent<{ class?: string }> = (props) => {
  return (
    <section
      class={`${props.class} flex w-full gap-2 border-b pb-2 text-gray-500`}
    >
      {props.children}
    </section>
  );
};

import { SearchSm } from "@liftedcare/solidjs-untitled-icons/duocolor";
import { A } from "@solidjs/router";
import { createQuery } from "@tanstack/solid-query";
import { debounce } from "lodash-es";
import { Component, createSignal, For, Match, Show, Switch } from "solid-js";

import { graphql } from "../../schema";
import gqlClient from "../../schema/gqlAuthorizedRequest";
import {
  CalendlyMeetingStatus,
  CalendlyMeetingType,
} from "../../schema/graphql";
import { enumToUcFirstLabel } from "../../utils/enumToLabel";
import { Button } from "../common/Button";
import DataLoadingSwitch from "../common/DataLoadingSwitch";
import { DateValue } from "../common/DateValue";
import { Layout } from "../common/Layout";
import { PageHeader } from "../common/PageHeader";
import { Pagination } from "../common/Pagination";
import { Pill } from "../common/Pill";
import { Table, Td, Th, Tr } from "../common/TableComponents";
import Input from "../form/Input";

const ITEMS_LIMIT = 20;

const getMeetingsQueryGql = graphql(`
  query getUpcomingCalendlyMeetings(
    $searchQuery: String
    $offset: Int
    $limit: Int
    $includePastMeetings: Boolean
  ) {
    getUpcomingCalendlyMeetings(
      searchQuery: $searchQuery
      offset: $offset
      limit: $limit
      includePastMeetings: $includePastMeetings
    ) {
      items {
        id
        applicantId
        name
        meetingUrl
        status
        eventStartDate
        eventEndDate
        type
      }
      totalCount
    }
  }
`);

export const CalendlyMeetingsList: Component = () => {
  const [currentPage, setCurrentPage] = createSignal(0);
  const [searchQuery, setSearchQuery] = createSignal<string | null>(null);

  const meetings = createQuery(() => ({
    queryKey: ["calendlyMeetings", searchQuery(), currentPage()],
    queryFn: async () =>
      gqlClient.request(getMeetingsQueryGql, {
        searchQuery: searchQuery(),
        offset: currentPage() * ITEMS_LIMIT,
        limit: ITEMS_LIMIT,
      }),
  }));

  const debounceSearch = debounce((query: string) => {
    setCurrentPage(0);
    setSearchQuery(query as string);
  }, 500);

  return (
    <Layout class="space-y-6">
      <PageHeader
        parentTitle="Admin"
        title="Meetings"
        subtitle="See all of scheduled meetings with applicants."
      >
        <Input
          placeholder="Search and press enter"
          onChange={(e) => {
            debounceSearch(e.toString());
          }}
          value={searchQuery() ?? ""}
          containerClass="max-w-sm"
          prefixIcon={<SearchSm class="w-4 text-gray-400" />}
        />
      </PageHeader>
      <Table class="overflow-visible bg-white">
        <thead>
          <tr>
            <Th>Name</Th>
            <Th>Status</Th>
            <Th>Type</Th>
            <Th>Applicant</Th>
            <Th>Start</Th>
            <Th>End</Th>
            <Th />
            {/* <Th>Posted to</Th> */}
          </tr>
        </thead>
        <tbody>
          <DataLoadingSwitch loader={meetings}>
            <For each={meetings.data?.getUpcomingCalendlyMeetings.items}>
              {(meeting) => {
                return (
                  <Tr>
                    <Td>{meeting?.name}</Td>
                    <Td>
                      <MeetingStatus status={meeting?.status} />
                    </Td>
                    <Td>
                      <MeetingType type={meeting?.type} />
                    </Td>
                    <Td class="font-medium text-primary-800 hover:underline">
                      <Show when={meeting?.applicantId}>
                        <A
                          href={`/applicant/profile/${meeting?.applicantId}/compliance`}
                        >
                          View
                        </A>
                      </Show>
                    </Td>
                    <Td>
                      <DateValue date={meeting?.eventStartDate} includeTime />
                    </Td>
                    <Td>
                      <DateValue date={meeting?.eventEndDate} includeTime />
                    </Td>
                    <Td>
                      <Show
                        when={meeting?.status === CalendlyMeetingStatus.Active}
                      >
                        <A target="_blank" href={meeting?.meetingUrl}>
                          <Button>Join</Button>
                        </A>
                      </Show>
                    </Td>
                  </Tr>
                );
              }}
            </For>
            <Tr>
              <Td colspan={7}>
                <Pagination
                  totalCount={
                    meetings?.data?.getUpcomingCalendlyMeetings.totalCount ?? 0
                  }
                  itemsPerPage={ITEMS_LIMIT}
                  currentPage={currentPage()}
                  setNewPage={setCurrentPage}
                />
              </Td>
            </Tr>
          </DataLoadingSwitch>
        </tbody>
      </Table>
    </Layout>
  );
};

const MeetingStatus: Component<{ status: CalendlyMeetingStatus }> = (props) => {
  return (
    <Switch>
      <Match when={props.status === CalendlyMeetingStatus.Active}>
        <Pill icon={false} type="success">
          {enumToUcFirstLabel(props.status)}
        </Pill>
      </Match>
      <Match when={props.status === CalendlyMeetingStatus.Canceled}>
        <Pill icon={false} type="error">
          {enumToUcFirstLabel(props.status)}
        </Pill>
      </Match>
    </Switch>
  );
};

const MeetingType: Component<{ type: CalendlyMeetingType }> = (props) => {
  return (
    <Switch>
      <Match when={props.type === CalendlyMeetingType.IntroCall}>
        <Pill icon={false} type="primary">
          {enumToUcFirstLabel(props.type)}
        </Pill>
      </Match>
    </Switch>
  );
};

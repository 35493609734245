import { useLocation, useNavigate } from "@solidjs/router";
import { createQuery } from "@tanstack/solid-query";
import {
  Accessor,
  createContext,
  createEffect,
  createSignal,
  ParentComponent,
  Show,
  useContext,
} from "solid-js";

import { graphql } from "../../schema";
import gqlClient from "../../schema/gqlAuthorizedRequest";
import { Agency, AgencyStatus, UserRoleType } from "../../schema/graphql";
import { useAuth } from "./AuthContext";

export const AgencyContext = createContext<{
  agency: Accessor<Partial<Agency> | null>;
}>();

const getAgencyAuthBarrierQuery = graphql(`
  query getAgencyAuthBarrier {
    getAgencyForSelfRegister {
      agency {
        id
        name
        status
        emailDomain
      }
    }
  }
`);

export const AgencyContextProvider: ParentComponent = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();

  const [agency, setAgency] = createSignal<Partial<Agency> | null>(null);
  const agencyWithOnboarding = createQuery(() => ({
    queryKey: ["userAgencyOnboarding"],
    queryFn: async () => {
      const data = await gqlClient.request(getAgencyAuthBarrierQuery);
      setAgency(data.getAgencyForSelfRegister.agency ?? null);
      return data;
    },
  }));

  createEffect(() => {
    if (agencyWithOnboarding.isLoading) {
      return;
    }

    if (
      agencyWithOnboarding.data?.getAgencyForSelfRegister?.agency?.status ===
      null
    ) {
      return;
    }
    if (auth?.authenticatedUser()?.role !== UserRoleType.Client) {
      return;
    }

    const insideAgencyRegister = location.pathname.startsWith("/register");

    const notStarted =
      agencyWithOnboarding.data?.getAgencyForSelfRegister.agency === null ||
      agencyWithOnboarding.data?.getAgencyForSelfRegister.agency?.status ===
        AgencyStatus.Onboarding;

    if (notStarted && !insideAgencyRegister) {
      navigate("/register/agency");
    }

    if (
      insideAgencyRegister &&
      agencyWithOnboarding.data?.getAgencyForSelfRegister?.agency?.status ===
        AgencyStatus.Onboarded
    ) {
      navigate("/candidates/sponsored-uk");
    }
  });

  return (
    <AgencyContext.Provider
      value={{
        agency,
      }}
    >
      <Show when={agencyWithOnboarding.isStale}>{props.children}</Show>
    </AgencyContext.Provider>
  );
};

export const useAgencyContext = () => useContext(AgencyContext);

import {
  createEffect,
  createSignal,
  mergeProps,
  ParentComponent,
} from "solid-js";

export type ButtonColor = "primary" | "secondary" | "danger" | "icon";
type ButtonType = "button" | "submit";

export const iconBtnStyles = (isDisabled = false) =>
  `${
    isDisabled ? "cursor-not-allowed" : "cursor-pointer hover:text-gray-900"
  } w-5 h-5`;

export interface ButtonProps {
  type?: ButtonType;
  onClick?: (e: Event) => void;
  color?: ButtonColor;
  class?: string;
  disabled?: boolean;
  title?: string;
  ariaLabel?: string;
}

const getColorClass = (props: ButtonProps) => {
  const disabled = () => props.disabled;
  const color = () => props.color;

  if (disabled()) {
    return color() === "icon"
      ? "text-gray-400 cursor-not-allowed border border-transparent"
      : "border border-gray-400 bg-gray-400 cursor-not-allowed";
  }

  switch (color() ?? "primary") {
    case "primary": {
      return "border border-transparent bg-primary-600 text-white hover:bg-primary-700 focus-visible:ring-2  shadow-sm";
    }
    case "secondary": {
      return "border border-gray-400 bg-white hover:bg-gray-200 shadow-sm";
    }
    case "danger": {
      return "border border-transparent bg-error-600 text-white hover:bg-error-700 focus-visible:ring-2  shadow-sm";
    }
    case "icon": {
      return "border border-transparent bg-transparent shadow-none hover:bg-transparent focus-visible:ring-0  hover:text-gray-900";
    }
    default: {
      throw new Error("Invalid color");
    }
  }
};

export const Button: ParentComponent<ButtonProps> = (props) => {
  const mergedProps = mergeProps({ type: "button", color: "primary" }, props);

  const [colorClass, setColorClass] = createSignal<string>(
    getColorClass(props),
  );

  createEffect(() => {
    setColorClass(getColorClass(props));
  });

  return (
    <button
      type={mergedProps.type as ButtonType}
      class={`${
        props.class
      } ${colorClass()} relative flex flex-row items-center justify-center whitespace-nowrap rounded-md px-3 py-2 text-sm transition-colors duration-300`}
      onClick={(e) => mergedProps.onClick?.(e)}
      disabled={props.disabled}
      title={props.title}
      aria-label={props.ariaLabel}
    >
      {mergedProps.children}
    </button>
  );
};

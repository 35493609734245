import { createQuery } from "@tanstack/solid-query";
import {
  createContext,
  createSignal,
  ParentComponent,
  Show,
  useContext,
} from "solid-js";

import { graphql } from "../../schema";
import gqlClient from "../../schema/gqlAuthorizedRequest";
import {
  GetAgenciesForAdminFragment,
  UserRoleType,
} from "../../schema/graphql";
import { useAuth } from "../auth/AuthContext";

export const AdminAgencyContext = createContext<{
  agencyId: () => number | null;
  agencyName: () => string | null;
  agencies: () => GetAgenciesForAdminFragment[];
  setCurrentAgency: (agencyId: number | null) => void;
}>();

graphql(`
  fragment GetAgenciesForAdmin on Agency {
    id
    name
  }
`);

const getAgenciesQuery = graphql(`
  query getAgenciesForAdmin {
    getAgencies {
      ...GetAgenciesForAdmin
    }
  }
`);

const ADMIN_AGENCY_KEY = "adminSelectedAgencyId";

const getStoredAgencyId = () => {
  const storedAgencyId = sessionStorage.getItem(ADMIN_AGENCY_KEY);
  return storedAgencyId ? Number.parseInt(storedAgencyId) : null;
};

export const AdminAgencyContextProvider: ParentComponent = (props) => {
  const auth = useAuth();

  const [currentAgency, setCurrentAgency] = createSignal<number | null>(
    getStoredAgencyId(),
  );

  const agenciesQuery = createQuery(() => ({
    queryKey: ["adminAgencies"],
    queryFn: async () => {
      const data = await gqlClient.request(getAgenciesQuery);
      data.getAgencies.sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      );
      return data;
    },
    enabled: auth?.authenticatedUser()?.role === UserRoleType.Admin,
  }));

  const getAgencies = () => {
    if (auth?.authenticatedUser()?.role !== UserRoleType.Admin) {
      return [];
    }
    return agenciesQuery.data?.getAgencies ?? [];
  };

  const getCurrentAgencyId = () => {
    if (auth?.authenticatedUser()?.role !== UserRoleType.Admin) {
      return null;
    }
    return currentAgency();
  };

  const getAgencyName = () => {
    if (auth?.authenticatedUser()?.role !== UserRoleType.Admin) {
      return null;
    }
    return (
      agenciesQuery.data?.getAgencies.find((v) => v.id === currentAgency())
        ?.name ?? null
    );
  };

  const setCurrentAdminAgency = (agencyId: number | null) => {
    setCurrentAgency(agencyId);
    sessionStorage.setItem(ADMIN_AGENCY_KEY, agencyId?.toString() ?? "");
  };

  return (
    <AdminAgencyContext.Provider
      value={{
        agencyId: getCurrentAgencyId,
        agencyName: getAgencyName,
        agencies: getAgencies,
        setCurrentAgency: setCurrentAdminAgency,
      }}
    >
      <Show
        when={
          agenciesQuery.isPending ||
          agenciesQuery.isStale ||
          !auth?.authenticatedUser()
        }
      >
        {props.children}
      </Show>
    </AdminAgencyContext.Provider>
  );
};

export const useAdminAgencyContext = () => useContext(AdminAgencyContext);

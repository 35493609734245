import { useNavigate, useParams } from "@solidjs/router";
import { createEffect, ParentProps } from "solid-js";

export function catchAll(redirect: string) {
  return {
    path: "*",
    component: (props: ParentProps) => {
      const navigate = useNavigate();
      const params = useParams();

      createEffect(() => {
        // set any params in the redirect path
        navigate(
          redirect.replaceAll(/:\w+/g, (match) => params[match.slice(1)]),
        );
      });

      return <>{props.children}</>;
    },
  };
}

import { Component } from "solid-js";

import { Button } from "./Button";

interface IPaginationProps {
  totalCount: number;
  itemsPerPage: number;
  currentPage: number;
  setNewPage: (newPage: number) => void;
}

export const Pagination: Component<IPaginationProps> = (props) => {
  const totalPages = () => Math.ceil(props.totalCount / props.itemsPerPage);

  return (
    <div class="flex w-full items-center justify-between">
      <div>
        Page {props.currentPage + 1} of {Math.max(totalPages(), 1)}
      </div>
      <div class="flex items-center">
        <Button
          color="secondary"
          class="mr-2"
          disabled={props.currentPage < 1}
          onClick={() => props.setNewPage(props.currentPage - 1)}
        >
          Previous
        </Button>
        <Button
          color="secondary"
          disabled={props.currentPage + 1 >= totalPages()}
          onClick={() => props.setNewPage(props.currentPage + 1)}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

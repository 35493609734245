import {
  Accessor,
  createContext,
  createSignal,
  JSXElement,
  ParentComponent,
  useContext,
} from "solid-js";

export const AgencySettingsContext = createContext<{
  headerContentRight: Accessor<JSXElement | null>;
  setHeaderContentRight: (elm: JSXElement) => void;
}>();

export const useAgencySettings = () => useContext(AgencySettingsContext);

export const AgencySettingsContextProvider: ParentComponent = (props) => {
  const [headerContentRight, setHeaderContentRight] =
    createSignal<JSXElement | null>();

  return (
    <AgencySettingsContext.Provider
      value={{
        headerContentRight,
        setHeaderContentRight,
      }}
    >
      {props.children}
    </AgencySettingsContext.Provider>
  );
};

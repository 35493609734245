import { createForm } from "@felte/solid";
import { Loading01 } from "@liftedcare/solidjs-untitled-icons/duocolor";
import { Component, createSignal, Show } from "solid-js";

import { graphql } from "../../../schema";
import gqlClient from "../../../schema/gqlAuthorizedRequest";
import { openDownloadUrl } from "../../../utils/openDownloadUrl";
import { Button } from "../../common/Button";
import { Checkbox } from "../../form/Checkbox";
import { FormContextProvider } from "../../form/FormContext";
import { FormLabel } from "../../form/FormLabel";
import Modal, {
  IModalProps,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../modal/Modal";
import { useToast } from "../../toast";

const getApplicantPdfExportQuery = graphql(`
  query getPdfExportUrlQuery($id: Int!) {
    getPdfExportUrl(id: $id) {
      success
      url
    }
  }
`);

export const ApplicantProfileExportModal: Component<
  IModalProps & { applicantId: number }
> = (props) => {
  const toast = useToast();

  const [downloading, setDownloading] = createSignal(false);

  const formMethods = createForm({
    onSubmit: (values) => {
      getPdfExport(values);
    },
    initialValues: {
      all: true,
      includeProfile: true,
      includeHealthDeclaration: true,
      includeLifeInTheUk: true,
      includeNameHistory: true,
      includeEmploymentHistory: true,
      includeAddressHistory: true,
      includeNationalityHistory: true,
      includeEducationHistory: true,
      includeNextOfKin: true,
      includeConvictions: true,
      includeDbs: true,
      includeDbsUpdate: true,
      includeReferences: true,
      includeIdvCheck: true,
      includeRtwAppearanceCheck: true,
      includeComments: true,
    },
  });
  const form = formMethods.form;

  const getPdfExport = async (config: Record<string, boolean>) => {
    setDownloading(true);
    const { getPdfExportUrl } = await gqlClient.request(
      getApplicantPdfExportQuery,
      { id: props.applicantId },
    );
    const response = await fetch(getPdfExportUrl.url, {
      method: "POST",
      body: JSON.stringify({ config }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.ok) {
      await openDownloadUrl(
        response,
        `applicant-export-${props.applicantId}.pdf`,
      );
    } else {
      toast.error("Error", "Error occurred while downloading PDF");
    }
    setDownloading(false);
    formMethods.reset();
    props.onClose();
  };

  return (
    <FormContextProvider formMethods={formMethods}>
      <form use:form>
        <Modal show={props.show} onClose={() => props.onClose()}>
          <ModalHeader>Export applicant data</ModalHeader>
          <ModalBody>
            <Show
              when={!downloading()}
              fallback={
                <div class="flex flex-col items-center justify-center space-y-4">
                  <p>Please wait while we prepare the PDF for download</p>
                  <Loading01 class="w-8 animate-spin" />
                </div>
              }
            >
              <p class="mb-6">
                Please select the sections you'd like to export
              </p>

              <div class="flex gap-2">
                <FormLabel class="text-sm font-medium">Toggle all</FormLabel>

                <Checkbox
                  name="all"
                  onChange={(value) => {
                    formMethods.setFields({
                      all: value,
                      includeProfile: value,
                      includeHealthDeclaration: value,
                      includeLifeInTheUk: value,
                      includeNameHistory: value,
                      includeEmploymentHistory: value,
                      includeAddressHistory: value,
                      includeNationalityHistory: value,
                      includeEducationHistory: value,
                      includeNextOfKin: value,
                      includeConvictions: value,
                      includeDbs: value,
                      includeDbsUpdate: value,
                      includeReferences: value,
                      includeIdvCheck: value,
                      includeRtwAppearanceCheck: value,
                      includeComments: value,
                    });
                  }}
                />
              </div>

              <section class="grid grid-cols-2 gap-6">
                <div class="flex justify-between">
                  <FormLabel for="includeProfile">Profile</FormLabel>
                  <span>
                    <Checkbox id="includeProfile" name="includeProfile" />
                  </span>
                </div>
                <div class="flex justify-between">
                  <FormLabel for="includeHealthDeclaration">
                    Health Declaration
                  </FormLabel>

                  <span>
                    <Checkbox
                      id="includeHealthDeclaration"
                      name="includeHealthDeclaration"
                    />
                  </span>
                </div>
                <div class="flex justify-between">
                  <FormLabel for="includeLifeInTheUk">Life in the UK</FormLabel>

                  <span>
                    <Checkbox
                      id="includeLifeInTheUk"
                      name="includeLifeInTheUk"
                    />
                  </span>
                </div>

                <div class="flex justify-between">
                  <FormLabel for="includeNameHistory">Name history</FormLabel>

                  <span>
                    <Checkbox
                      id="includeNameHistory"
                      name="includeNameHistory"
                    />
                  </span>
                </div>

                <div class="flex justify-between">
                  <FormLabel for="includeEmploymentHistory">
                    Employment history
                  </FormLabel>

                  <span>
                    <Checkbox
                      id="includeEmploymentHistory"
                      name="includeEmploymentHistory"
                    />
                  </span>
                </div>

                <div class="flex justify-between">
                  <FormLabel for="includeAddressHistory">
                    Address history
                  </FormLabel>

                  <span>
                    <Checkbox
                      id="includeAddressHistory"
                      name="includeAddressHistory"
                    />
                  </span>
                </div>

                <div class="flex justify-between">
                  <FormLabel for="includeNationalityHistory">
                    Nationality history
                  </FormLabel>

                  <span>
                    <Checkbox
                      id="includeNationalityHistory"
                      name="includeNationalityHistory"
                    />
                  </span>
                </div>

                <div class="flex justify-between">
                  <FormLabel for="includeEducationHistory">
                    Education history
                  </FormLabel>

                  <span>
                    <Checkbox
                      id="includeEducationHistory"
                      name="includeEducationHistory"
                    />
                  </span>
                </div>

                <div class="flex justify-between">
                  <FormLabel for="includeNextOfKin">Next of kin</FormLabel>

                  <span>
                    <Checkbox id="includeNextOfKin" name="includeNextOfKin" />
                  </span>
                </div>

                <div class="flex justify-between">
                  <FormLabel for="includeConvictions">Convictions</FormLabel>

                  <span>
                    <Checkbox
                      id="includeConvictions"
                      name="includeConvictions"
                    />
                  </span>
                </div>

                <div class="flex justify-between">
                  <FormLabel for="includeDbs">DBS</FormLabel>

                  <span>
                    <Checkbox id="includeDbs" name="includeDbs" />
                  </span>
                </div>

                <div class="flex justify-between">
                  <FormLabel for="includeDbsUpdate">DBS update</FormLabel>

                  <span>
                    <Checkbox id="includeDbsUpdate" name="includeDbsUpdate" />
                  </span>
                </div>

                <div class="flex justify-between">
                  <FormLabel for="includeReferences">References</FormLabel>

                  <span>
                    <Checkbox id="includeReferences" name="includeReferences" />
                  </span>
                </div>

                <div class="flex justify-between">
                  <FormLabel for="includeIdvCheck">IDV check</FormLabel>

                  <span>
                    <Checkbox id="includeIdvCheck" name="includeIdvCheck" />
                  </span>
                </div>

                <div class="flex justify-between">
                  <FormLabel for="includeRtwAppearanceCheck">
                    RTW visual appearance check
                  </FormLabel>

                  <span>
                    <Checkbox
                      id="includeRtwAppearanceCheck"
                      name="includeRtwAppearanceCheck"
                    />
                  </span>
                </div>

                <div class="flex justify-between">
                  <FormLabel for="includeComments">Comments</FormLabel>

                  <span>
                    <Checkbox id="includeComments" name="includeComments" />
                  </span>
                </div>
              </section>
            </Show>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={props.onClose}
              disabled={downloading()}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={formMethods.handleSubmit}
              disabled={downloading()}
            >
              Export
            </Button>
          </ModalFooter>
        </Modal>
      </form>
    </FormContextProvider>
  );
};

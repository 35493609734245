export const openDownloadUrl = async (response: Response, fileName: string) => {
  // download the file using fetch and open a new tab if successful
  // https://stackoverflow.com/a/9970672
  const blob = await response.blob();
  const url = window.URL.createObjectURL(blob);
  const a = window.document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = fileName;
  window.document.body.append(a);
  a.click();
  window.URL.revokeObjectURL(url);
};

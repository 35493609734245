import { RouteDefinition, useNavigate } from "@solidjs/router";
import { lazy } from "solid-js";

import ApplicantProfileRouter from "../components/applicants/profile/ApplicantProfileRouter";
import { ApplicantStatusTypeType } from "../schema/graphql";
import { catchAll } from "./catch-all.routes";

const ApplicantList = lazy(
  () => import("../components/applicants/ApplicantList"),
);
const ApplicantsEventsList = lazy(
  () => import("../components/applicants/events/ApplicantsEventsList"),
);

export const applicantRoutes: RouteDefinition[] = [
  {
    path: "/applicants/compliance-in-progress",
    component: () => {
      const navigate = useNavigate();
      navigate("/applicants/list");
      return <></>;
    },
  },
  {
    path: "/applicants",
    children: [
      catchAll("/applicants/list"),
      {
        path: "/list",
        component: () => (
          <ApplicantList
            title="Overview"
            applicantStatusFilter={[
              ApplicantStatusTypeType.ComplianceStarted,
              ApplicantStatusTypeType.AwaitingSignOff,
              ApplicantStatusTypeType.OfferAccepted,
              ApplicantStatusTypeType.OfferMade,
              ApplicantStatusTypeType.OfferSent,
            ]}
          />
        ),
      },
      {
        path: "/events",
        component: () => <ApplicantsEventsList />,
      },
    ],
  },
  {
    path: "/applicant/profile/:id/*",
    component: ApplicantProfileRouter,
    children: [
      {
        path: "/compliance",
        component: lazy(
          () =>
            import(
              "../components/applicants/compliance-overview/ComplianceOverviewView"
            ),
        ),
      },
      {
        path: "/actions",
        component: lazy(
          () => import("../components/applicants/actions/ActionsView"),
        ),
      },
      {
        path: "/profile",
        component: lazy(
          () => import("../components/applicants/profile/ApplicantProfile"),
        ),
      },
      {
        path: "/history",
        component: lazy(
          () => import("../components/applicants/profile/ApplicantHistoryView"),
        ),
      },
      {
        path: "/identity",
        component: lazy(
          () => import("../components/applicants/identity/IdentityView"),
        ),
      },
      {
        path: "/documents",
        component: lazy(
          () => import("../components/applicants/documents/DocumentsView"),
        ),
      },
      {
        path: "/references",
        component: lazy(
          () => import("../components/applicants/profile/ReferencesListView"),
        ),
      },
      {
        path: "/references/:referenceRequestId",
        component: lazy(
          () => import("../components/applicants/profile/ReferenceView"),
        ),
      },
      {
        path: "/dbs",
        component: lazy(() => import("../components/applicants/dbs/DbsView")),
      },
      {
        path: "/right-to-work",
        component: lazy(
          () =>
            import("../components/applicants/right-to-work/RightToWorkView"),
        ),
      },
      {
        path: "/comments",
        component: lazy(
          () => import("../components/applicants/profile/CommentsView"),
        ),
      },
      {
        path: "/accommodation",
        component: lazy(
          () =>
            import("../components/applicants/accommodation/AccommodationView"),
        ),
      },
      {
        path: "/flights",
        component: lazy(
          () => import("../components/applicants/flights/FlightsView"),
        ),
      },
      {
        path: "/vehicles",
        component: lazy(
          () => import("../components/applicants/vehicles/VehiclesView"),
        ),
      },
      {
        path: "/health-declaration",
        component: lazy(
          () =>
            import(
              "../components/applicants/health-declaration/HealthDeclarationView"
            ),
        ),
      },
      {
        path: "/app-config",
        component: lazy(
          () => import("../components/applicants/profile/AppConfigView"),
        ),
      },
      {
        path: "/signature-requests",
        component: lazy(
          () =>
            import(
              "../components/applicants/signature-requests/SignatureRequestsView"
            ),
        ),
      },
    ],
  },
];

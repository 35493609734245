import { Component, createSignal, Show } from "solid-js";

function getColor(i: number) {
  const colors = [
    "bg-primary-700",
    "bg-pink-700",
    "bg-teal-700",
    "bg-cyan-700",
    "bg-violet-500",
    "bg-fuchsia-700",
    "bg-pink-500",
  ];
  return colors[i % colors.length];
}

const [bgColor, setBgColor] = createSignal(new Map());

const getBgColor = (initials: string) => {
  const colors = bgColor();
  if (colors.has(initials)) {
    return colors.get(initials);
  }
  const color = getColor(colors.size);
  colors.set(initials, color);
  setBgColor(colors);
  return color;
};

export const UserAvatar: Component<{
  imgSrc?: string;
  firstName?: string | null;
  lastName?: string | null;
  size: "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl";
  class?: string;
  shape?: "round" | "square";
}> = (props) => {
  const initials = () =>
    `${props.firstName?.[0] ?? ""}${props.lastName?.[0] ?? ""}`.toUpperCase();

  const shapeCss = () =>
    props.shape === "square" ? "rounded-xl" : "rounded-full";

  const classes = () =>
    `${
      props.class
    } ${shapeCss()} text-white flex justify-center items-center object-cover ${getBgColor(
      initials(),
    )}`;

  const classList = () => ({
    "w-8 h-8 text-sm": props.size === "xs",
    "w-10 h-10": props.size === "sm",
    "w-12 h-12": props.size === "md" || !props.size, // the default
    "w-16 h-16": props.size === "lg",
    "w-24 h-24": props.size === "xl",
    "w-32 h-32": props.size === "2xl",
    "w-48 h-48": props.size === "3xl",
  });

  return (
    <Show
      when={props.imgSrc}
      fallback={
        <div class={classes()} classList={classList()}>
          {initials()}
        </div>
      }
    >
      <img
        src={props.imgSrc}
        alt={initials()}
        class={classes()}
        classList={classList()}
      />
    </Show>
  );
};

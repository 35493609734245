import { RouteDefinition } from "@solidjs/router";
import { lazy } from "solid-js";

import { ApplicationStatusEnumType } from "../schema/graphql";

const ApplicationReview = lazy(
  () => import("../components/applications/ApplicationReview"),
);
const ApplicationsList = lazy(
  () => import("../components/applications/ApplicationsList"),
);

const applicantReviewPath = {
  path: "/:id",
  component: ApplicationReview,
};
const statusSubPaths = [
  {
    path: "/review",
    status: ApplicationStatusEnumType.ApplicationReview,
    title: "Applicant Review",
    subTitle: "Review your new job applicants",
  },
  {
    path: "/screening",
    status: ApplicationStatusEnumType.Screening,
    title: "Screening",
    subTitle: "Review applicants in screening stage",
  },
  {
    path: "/interviewing",
    status: ApplicationStatusEnumType.Interviewing,
    title: "Interviewing",
    subTitle: "Review applicants in interviewing stage",
  },
  {
    path: "/rejected",
    status: ApplicationStatusEnumType.Rejected,
    title: "Rejected",
    subTitle: "Review applicants that have been rejected",
  },
];
export const applicationRoutes: RouteDefinition[] = [
  {
    path: "/applications",
    children: statusSubPaths.map((config) => ({
      path: config.path,
      component: () => (
        <ApplicationsList
          title={config.title}
          subTitle={config.subTitle}
          status={config.status}
        />
      ),
      children: [applicantReviewPath],
    })),
  },
];

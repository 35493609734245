import * as Sentry from "@sentry/browser";

import { isGqlRequestError } from "./isGqlRequestError";

export function handleGqlError(
  err: unknown,
  redirectUnauthorized = false,
  fallbackMessage: string,
) {
  if (
    err instanceof Error &&
    isGqlRequestError(err) &&
    err.response.errors?.length
  ) {
    err.response.errors.map((error) => {
      let message = fallbackMessage;
      if (error.path) {
        message = `Error during request "${error.path.join(".")}": ${
          error.message ?? "Unknown error"
        }`;
      }
      if (
        error?.extensions?.errorCode &&
        !["UNAUTHORIZED", "VALIDATION_FAILED"].includes(
          error.extensions.errorCode as string,
        )
      ) {
        const requestVariables = err.request.variables;
        Sentry.captureException(new Error(message), {
          extra: {
            statusCode: error.extensions?.statusCode,
            errorCode: error.extensions?.errorCode,
            requestVariables,
          },
        });
      }

      console.error(message);
    });

    if (err.response.status === 401 && redirectUnauthorized) {
      window.location.assign("/forbidden");
    }
  }
}

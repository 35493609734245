import { Component } from "solid-js";

import { formatDate } from "../../utils/formatDate";

export const DateValue: Component<{
  date?: Date | null | string;
  format?: string;
  includeTime?: boolean;
  fallback?: string;
  invalidFallback?: string;
  class?: string;
}> = (props) => {
  const formattedDate = () => {
    try {
      let date: Date;
      if (props.date instanceof Date) {
        date = props.date;
      } else if (typeof props.date === "string") {
        date = new Date(props.date);
      } else {
        return "";
      }
      return formatDate(date, props.format, props.includeTime);
    } catch {
      return props.invalidFallback ?? "Invalid date";
    }
  };

  return (
    <span class={props.class}>
      {props.date ? formattedDate() : (props.fallback ?? "")}
    </span>
  );
};

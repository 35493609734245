import { QueryClient } from "@tanstack/solid-query";

export const getQueryClient = (
  onMutationError?: (e: unknown, variables: unknown, context: unknown) => void,
) => {
  return new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        gcTime: undefined,
        retry: 1,
      },
      mutations: {
        onError: onMutationError,
      },
    },
  });
};

import {
  Download01,
  FileLock02,
} from "@liftedcare/solidjs-untitled-icons/duocolor";
import { useBeforeLeave, useParams } from "@solidjs/router";
import { createQuery } from "@tanstack/solid-query";
import { Component, createSignal, useContext } from "solid-js";

import { graphql } from "../../../schema";
import gqlClient from "../../../schema/gqlAuthorizedRequest";
import { UserRoleType } from "../../../schema/graphql";
import { getName } from "../../../utils/getName";
import { BackButton } from "../../common/BackButton";
import { Button } from "../../common/Button";
import { PageHeader } from "../../common/PageHeader";
import { ShowForRoles } from "../../common/ShowForRoles";
import { ApplicantExportTokenModal } from "../actions/ApplicantExportTokenModal";
import { ApplicantProfileContext } from "./ApplicantProfileContext";
import { ApplicantProfileExportModal } from "./ApplicantProfileExportModal";

const getApplicantNameQuery = graphql(`
  query getApplicantName($id: Int!) {
    getApplicant(id: $id) {
      currentName {
        firstName
        lastName
      }
    }
  }
`);

export const ApplicantProfileHeader: Component = () => {
  const applicantId = Number.parseInt(useParams().id, 10);

  const applicantProfileContext = useContext(ApplicantProfileContext);

  const [showPdfExportModal, setShowPdfExportModal] = createSignal(false);
  const [showExportTokenModal, setShowExportTokenModal] = createSignal(false);

  const applicantName = createQuery(() => ({
    queryKey: ["applicantName", applicantId],
    queryFn: async () => {
      return gqlClient.request(getApplicantNameQuery, {
        id: applicantId,
      });
    },
  }));

  // this clears the content right each time the route changes
  useBeforeLeave(() => {
    applicantProfileContext?.setHeaderContentRight(null);
    applicantProfileContext?.setBackButton({
      href: "/applicants/list",
      text: "Back",
    });
  });

  return (
    <section class="mb-4 flex">
      <div class="grow">
        <BackButton to="/applicants/list" />

        <PageHeader
          parentTitle="Team"
          title={
            <div class="flex items-center gap-2">
              <span>
                {getName(applicantName.data?.getApplicant?.currentName)}
              </span>
              <ShowForRoles roles={[UserRoleType.Admin]}>
                <button onClick={() => setShowExportTokenModal(true)}>
                  <FileLock02 class="h-6 text-primary-700" />
                </button>
              </ShowForRoles>
            </div>
          }
          subtitle={
            <>
              View {applicantName.data?.getApplicant?.currentName?.firstName}
              's onboarding file.
            </>
          }
        >
          <div class="flex items-start gap-2">
            {applicantProfileContext?.headerContentRight()}
            <div>
              <Button
                class="flex items-center gap-2"
                color="secondary"
                onClick={() => setShowPdfExportModal(true)}
              >
                <Download01 class="size-4" />
                Export
              </Button>
            </div>
          </div>
        </PageHeader>
      </div>

      <ApplicantProfileExportModal
        applicantId={applicantId}
        show={showPdfExportModal()}
        onClose={() => setShowPdfExportModal(false)}
      />

      <ApplicantExportTokenModal
        applicantId={applicantId}
        show={showExportTokenModal()}
        onClose={() => setShowExportTokenModal(false)}
      />
    </section>
  );
};

/* @refresh reload */
import "./index.css";

import { Router } from "@solidjs/router";
import { lazy } from "solid-js";
import { render } from "solid-js/web";

import App from "./App";
import { Main } from "./components/Home";
import { CalendlyMeetingsList } from "./components/meetings/CalendlyMeetingsList";
import { NotFound } from "./components/NotFound";
import { agencyRoutes } from "./routes/agency.routes";
import { applicantRoutes } from "./routes/applicant.routes";
import { applicationRoutes } from "./routes/application.routes";
import { authRoutes } from "./routes/auth.routes";
import { candidatesRoutes } from "./routes/candidate.routes";
import { complianceRoutes } from "./routes/compliance.routes";
import { RouteGuard } from "./routes/RouteGuard";
import { sponsorSwitchRoutes } from "./routes/sponsor-switch.routes";
import { stagedCandidateRoutes } from "./routes/staged-candidate.routes";
import { UserRoleType } from "./schema/graphql";

const ReferralsListView = lazy(
  () => import("./components/referrals/ReferralsListView"),
);

const routes = [
  {
    path: "/",
    component: Main,
  },
  {
    path: "/referrals/list",
    component: () => (
      <RouteGuard rolesAllowed={[UserRoleType.Admin]}>
        <ReferralsListView />
      </RouteGuard>
    ),
  },
  {
    path: "/meetings",
    component: CalendlyMeetingsList,
  },
  ...authRoutes,
  ...applicationRoutes,
  ...applicantRoutes,
  ...agencyRoutes,
  ...sponsorSwitchRoutes,
  ...complianceRoutes,
  ...candidatesRoutes,
  ...stagedCandidateRoutes,
  {
    path: "*",
    component: () => <NotFound />,
  },
];

render(
  () => (
    <Router root={App}>
      {routes}

      {/* TODO: migrate these (currently unused) routes to use config based routing */}
      {/* <Route path="/positions/list" component={PositionList} />
      <Route path="/positions/add" component={PositionAdd} />
      <Route path="/positions/:id/edit" component={PositionEdit} /> */}
    </Router>
  ),
  document.getElementById("root") as HTMLElement,
);

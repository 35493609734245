import {
  fetchAndActivate,
  getAll,
  getRemoteConfig,
  Value,
} from "firebase/remote-config";
import {
  Accessor,
  createContext,
  createEffect,
  createSignal,
  ParentComponent,
  useContext,
} from "solid-js";

import { FirebaseApp } from "../../../firebaseConfig";
import { getEnvironment } from "../../utils/getEnvironment";

type RemoteConfigValue = Record<string, Value | undefined | null>;

export const remoteConfig = getRemoteConfig(FirebaseApp);
remoteConfig.settings.minimumFetchIntervalMillis = 900_000; // 15 mins
if (getEnvironment() === "development") {
  remoteConfig.settings.minimumFetchIntervalMillis = 300_000; // 5 mins
}

export interface IFeatureFlagsContext {
  flags: Accessor<RemoteConfigValue>;
}
export const FlagsContext = createContext<IFeatureFlagsContext | null>(null);

export const FeatureFlagsContext: ParentComponent = (props) => {
  const [flags, setFlags] = createSignal<RemoteConfigValue>({});

  createEffect(() => {
    fetchAndActivate(remoteConfig).then(() => {
      setFlags(getAll(remoteConfig));
    });
  });

  return (
    <FlagsContext.Provider value={{ flags }}>
      {props.children}
    </FlagsContext.Provider>
  );
};

export const useFeatureFlags = () => useContext(FlagsContext);

import { format as formatFn } from "date-fns";

export function formatDate(
  date?: Date | string | null,
  format?: string,
  includeTime?: boolean,
) {
  let formatString = format || "do MMM yyyy";
  if (includeTime) {
    formatString += " HH:mm";
  }

  return date ? formatFn(new Date(date), formatString) : "";
}

import { kebabCase, startCase } from "lodash-es";

export const enumToLabel = (val?: string | null): string =>
  (val ?? "").replaceAll("_", " ").toLowerCase();

const acronyms = ["UK"];
// eslint-disable-next-line security/detect-non-literal-regexp -- it's ok because the variable is a constant
const acronymsRegex = new RegExp(`\\b(${acronyms.join("|")})\\b`, "i");
export const enumToUcFirstLabel = (val?: string | null): string =>
  startCase(
    kebabCase(val ?? "")
      .replaceAll("_", " ")
      .toLowerCase()
      .replace(acronymsRegex, (value) => value.toUpperCase()),
  );

const documentTypeAcronyms = ["DBS", "BRP", "ID", "ECS", "UK"];
// eslint-disable-next-line security/detect-non-literal-regexp -- it's ok because the variable is a constant
const documentTypeAcronymsRegex = new RegExp(
  `\\b(${documentTypeAcronyms.join("|")})\\b`,
  "i",
);
export const documentTypeEnumToLabel = (val?: string | null): string =>
  enumToUcFirstLabel(val).replace(documentTypeAcronymsRegex, (value) =>
    value.toUpperCase(),
  );

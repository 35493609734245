import { ChevronRight } from "@liftedcare/solidjs-untitled-icons/line";
import { JSXElement, ParentComponent, Show } from "solid-js";

export const PageHeader: ParentComponent<{
  parentTitle: string;
  title: string | JSXElement;
  class?: string;
  subtitle?: string | JSXElement;
}> = (props) => {
  return (
    <header
      class={`flex flex-wrap items-center gap-4 border-b pb-4 ${props.class ?? ""}`}
    >
      <section class="grow flex-wrap space-y-2 overflow-hidden">
        <h1 class="flex flex-wrap items-center gap-1 whitespace-nowrap text-xl md:gap-2">
          <span class="font-medium">{props.parentTitle}</span>
          <ChevronRight class="size-4 text-gray-500" />
          <span class="font-medium text-primary-700">{props.title}</span>
        </h1>
        <Show when={props.subtitle}>
          <p class="text-sm text-gray-500">{props.subtitle}</p>
        </Show>
      </section>

      {props.children}
    </header>
  );
};

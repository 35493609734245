import { connectAuthEmulator, getAuth } from "firebase/auth";

import { FirebaseApp } from "../../firebaseConfig";

const auth = getAuth(FirebaseApp);
if (import.meta.env.VITE_FIREBASE_AUTH_EMULATOR_URL) {
  connectAuthEmulator(auth, import.meta.env.VITE_FIREBASE_AUTH_EMULATOR_URL);
}

export const getFirebaseAuth = () => auth;

import { Loading01 } from "@liftedcare/solidjs-untitled-icons/duocolor";
import { createMutation, useQueryClient } from "@tanstack/solid-query";
import { Component, createSignal, Show } from "solid-js";

import { graphql } from "../../schema";
import gqlClient from "../../schema/gqlAuthorizedRequest";
import { getGqlErrorMessage } from "../../utils/getGqlErrorMessage";
import { Button } from "../common/Button";
import { LoginLayout } from "../common/LoginLayout";
import { useToast } from "../toast";
import { useAuth } from "./AuthContext";

const resendVerificationEmailMutationGql = graphql(`
  mutation resendVerificationEmail {
    resendVerificationEmail {
      success
    }
  }
`);

const VerifyYourEmail: Component<{ onLogin?: () => void }> = () => {
  const auth = useAuth();
  const toast = useToast();
  const queryClient = useQueryClient();

  const [loading, setLoading] = createSignal(false);

  const emailVerified = () => {
    auth?.refreshData();
  };

  const resendVerificationEmailMutation = createMutation(() => ({
    mutationFn: async () => {
      return gqlClient.request(resendVerificationEmailMutationGql);
    },
    onSuccess: () => {
      toast.success(
        "Success",
        "Requested new verification email, if you have not received it please check your spam folder",
      );

      queryClient.invalidateQueries({
        queryKey: ["userAgencyOnboarding"],
      });

      queryClient.invalidateQueries({
        queryKey: ["agencyOnboarding"],
      });
      setLoading(false);
    },
    onError(error) {
      toast.error(
        "Error",
        getGqlErrorMessage(error, "Something went wrong, please try again"),
      );
      setLoading(false);
    },
  }));

  const resendVerificationEmail = () => {
    setLoading(true);
    resendVerificationEmailMutation.mutate();
  };

  return (
    <Show
      when={!loading()}
      fallback={
        <div class="flex size-full items-center justify-center">
          <Loading01 class="size-32 animate-spin text-2xl" />
        </div>
      }
    >
      <LoginLayout>
        <div class="flex h-full items-center justify-center">
          <div class="flex flex-col items-center gap-4 space-y-8">
            <h1 class="text-center text-xl font-medium text-primary-600">
              Verify your email
            </h1>
            <p class="text-center text-primary-500">
              Before we continue, we need to verify your email address. We have
              sent you an email containing a link. Please follow that link to
              complete your account setup.
            </p>
            <p class="text-center text-primary-500">
              If you haven't received the email please check your spam folder,
              or request a new link by clicking{" "}
              <button
                onClick={resendVerificationEmail}
                class="text-blue-600 underline"
              >
                here
              </button>
            </p>
            <Button onClick={emailVerified} class="!p-4">
              I have verified my email
            </Button>
          </div>
        </div>
      </LoginLayout>
    </Show>
  );
};

export default VerifyYourEmail;

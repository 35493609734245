import { CreateQueryResult } from "@tanstack/solid-query";
import { createMemo, JSX, Match, ParentComponent, Switch } from "solid-js";

import { Error } from "../Error";

interface DataLoadingSwitchProps {
  loader: CreateQueryResult<unknown>;
  loadingContent?: string | JSX.Element;
  errorContent?: string | JSX.Element;
}

const DataLoadingSwitch: ParentComponent<DataLoadingSwitchProps> = (props) => {
  const isLoading = createMemo(() => props.loader.isFetching);

  return (
    <Switch>
      <Match when={isLoading()}>
        {props.loadingContent ?? (
          <div class="flex size-full items-center justify-center">
            Loading...
          </div>
        )}
      </Match>
      <Match when={props.loader.isError}>
        <Error
          error={
            props.errorContent?.toString() ?? "Error occurred while loading"
          }
        />
      </Match>
      <Match when={props.loader.isSuccess}>{props.children}</Match>
    </Switch>
  );
};

export default DataLoadingSwitch;

import { Circle } from "@liftedcare/solidjs-untitled-icons/solid";
import { JSX, ParentComponent, Show } from "solid-js";

function getClass(
  bgColor: string,
  textColor: string,
  borderColor?: string,
  border = false,
) {
  return `${border ? `${borderColor} border` : ""} ${bgColor} ${textColor}`;
}

export type PillType = "success" | "warning" | "error" | "info" | "primary";
export const Pill: ParentComponent<{
  type?: PillType;
  icon?: JSX.Element | boolean;
  class?: string;
  border?: boolean;
}> = (props) => {
  const classes = () => {
    switch (props.type) {
      case "success": {
        return getClass(
          "bg-success-100",
          "text-success-800",
          "border-success-300",
          props.border,
        );
      }
      case "warning": {
        return getClass(
          "bg-warning-100",
          "text-warning-800",
          "border-warning-300",
          props.border,
        );
      }
      case "error": {
        return getClass(
          "bg-error-100",
          "text-error-700",
          "border-error-300",
          props.border,
        );
      }
      case "info": {
        return getClass(
          "bg-blue-100",
          "text-blue-800",
          "border-blue-300",
          props.border,
        );
      }
      case "primary": {
        return getClass(
          "bg-primary-100",
          "text-primary-700",
          "border-primary-300",
          props.border,
        );
      }
      default: {
        return getClass(
          "bg-gray-100",
          "text-gray-800",
          "border-gray-300",
          props.border,
        );
      }
    }
  };

  return (
    <span
      class={`${
        props.class
      } ${classes()} inline-flex grow-0 items-center gap-2 self-start whitespace-nowrap rounded-full px-3 py-1 text-xs`}
    >
      <Show
        when={props.icon}
        fallback={
          <Show when={props.icon !== false}>
            <Circle class="size-2" />
          </Show>
        }
      >
        {props.icon}
      </Show>
      {props.children}
    </span>
  );
};
